.slideshow-container{
	width: 97%;
	margin: auto;
	height: 100%;
	position: relative;


	.centerPoints{
		position: absolute;
		bottom: -30px;
		width: 100%;
		height: 30px;
		@media screen and (max-width: @responsiveThirdThresold){
			bottom: -20px;
		}
		.slideshow-points{
			display: inline-block;
			.sliderPoint{
				width: 29px;
				height: 29px;
				display: inline-block;
				background: url("../img/point.png");
				margin: 0 3px;
				cursor: pointer;
			}
			.sliderPoint.act{
				background: url("../img/point_act.png");
			}
		}
	}
				
	
	.control{
		cursor: pointer;
		width: 36px;
		height: 59px;
		display: inline-block;
		position: absolute;
		top: 40%;
		z-index: 3;
	}
	.control.arrowRight{
		background: url("../img/rightArrow.png");
		right: -40px;
	}
	.control.arrowLeft{
		background: url("../img/leftArrow.png");
		left: -50px;
	}

	.slideshow{
		position: relative;
		overflow: hidden;

		

		.container{
			position: relative;
			z-index: 1;
			.slide{
				float: left;
				position: relative;
				left: 0px;
				padding: 0;

				@media screen and (max-width: @responsiveThirdThresold){
					padding: 0 20px;
				}
				.fixedContent{
					position: absolute;
					z-index: 2;
					width: 100%;
				}
			}
		}

	}
}