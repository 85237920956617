@main:  #fff; //#f07818;
.main{ color: @main; }
.mainBlock{ background: @main; }
.mainLink a, a.mainLink{ color: @main; opacity: 0.8; }
.mainLink a:hover, a.mainLink:hover{ color: @main; opacity: 1;}

@mainLight: #F9974F;
.mainLight{ color: @mainLight; }
.mainLightBlock{ background: @mainLight; }
.mainLightLink a, a.mainLightLink{ color: @mainLight; opacity: 0.8; }
.mainLightLink a:hover, a.mainLightLink:hover{ color: @mainLight; opacity: 1;}

@mainLightest: #FCC196;
.mainLightest{ color: @mainLightest; }
.mainLightestBlock{ background: @mainLightest; }
.mainLightestLink a, a.mainLightestLink{ color: @mainLightest; opacity: 0.8; }
.mainLightestLink a:hover, a.mainLightestLink:hover{ color: @mainLightest; opacity: 1;}


@mainVeryDark: #5e412f;
.mainVeryDark{ color: @mainVeryDark; }
.mainVeryDarkBlock{ background: @mainVeryDark; }
.mainVeryDarkLink a, a.mainVeryDarkLink{ color: @mainVeryDark; opacity: 0.8; }
.mainVeryDarkLink a:hover, a.mainVeryDarkLink:hover{ color: @mainVeryDark; opacity: 1;}

@mainDark: mix(@main, @mainVeryDark, 50%);
.mainDark{ color: @mainDark; }
.mainDarkBlock{ background: @mainDark; }
.mainDarkLink a, a.mainDarkLink{ color: @mainDark; opacity: 0.8; }
.mainDarkLink a:hover, a.mainDarkLink:hover{ color: @mainDark; opacity: 1;}

@mainDarkest: #4f2f1b;
.mainDarkest{ color: @mainDarkest; }
.mainDarkestBlock{ background: @mainDarkest; }
.mainDarkestLink a, a.mainDarkestLink{ color: @mainDarkest; opacity: 0.8; }
.mainDarkestLink a:hover, a.mainDarkestLink:hover{ color: @mainDarkest; opacity: 1;}

@secondary: #8AAED1;
.secondary{ color: @secondary; }
.secondaryBlock{ background: @secondary; }
.secondaryLink a, a.secondaryLink{ color: @secondary; opacity: 0.8; }
.secondaryLink a:hover, a.secondaryLink:hover{ color: @secondary; opacity: 1;}

@secondaryDark: #457AAF;
.secondaryDark{ color: @secondaryDark; }
.secondaryDarkBlock{ background: @secondaryDark; }
.secondaryDarkLink a, a.secondaryDarkLink{ color: @secondaryDark; opacity: 0.8; }
.secondaryDarkLink a:hover, a.secondaryDarkLink:hover{ color: @secondaryDark; opacity: 1;}


@tertiary: #fcebb6;
.tertiary{ color: @tertiary; }
.tertiaryBlock{ background: @tertiary; }
.tertiaryLink a, a.tertiaryLink{ color: @tertiary; opacity: 0.8; }
.tertiaryLink a:hover, a.tertiaryLink:hover{ color: @tertiary; opacity: 1;}

@greyLightest: #eeeeee;
.greyLightest{ color: @greyLightest; }
.greyLightestBlock{ background: @greyLightest; }
.greyLightestLink a, a.greyLightestLink{ color: @greyLightest; opacity: 0.8; }
.greyLightestLink a:hover, a.greyLightestLink:hover{ color: @greyLightest; opacity: 1;}

@greyLight: #aaa;
.greyLight{ color: @greyLight; }
.greyLightBlock{ background: @greyLight; }
.greyLightLink a, a.greyLightLink{ color: @greyLight; opacity: 0.8; }
.greyLightLink a:hover, a.greyLightLink:hover{ color: @greyLight; opacity: 1;}

@grey: #707070;
.grey{ color: @grey; }
.greyBlock{ background: @grey; }
.greyLink a, a.greyLink{ color: @grey; opacity: 0.8; }
.greyLink a:hover, a.greyLink:hover{ color: @grey; opacity: 1;}

@greyDark: #535353;
.greyDark{ color: @greyDark; }
.greyDarkBlock{ background: @greyDark; }
.greyDarkLink a, a.greyDarkLink{ color: @greyDark; opacity: 0.8; }
.greyDarkLink a:hover, a.greyDarkLink:hover{ color: @greyDark; opacity: 1;}

@greyDarkest: #36322f;
.greyDarkest{ color: @greyDarkest; }
.greyDarkestBlock{ background: @greyDarkest; }
.greyDarkestLink a, a.greyDarkestLink{ color: @greyDarkest; opacity: 0.8; }
.greyDarkestLink a:hover, a.greyDarkestLink:hover{ color: @greyDarkest; opacity: 1;}

@black: #000;
.black{ color: @black; }
.blackBlock{ background: @black; }
.blackLink a, a.blackLink{ color: @black; opacity: 0.8; }
.blackLink a:hover, a.blackLink:hover{ color: @black; opacity: 1;}


@white: #fff;
.white{ color: @white; }
.whiteBlock{ background: @white; }
.whiteLink a, a.whiteLink{ color: @white; opacity: 0.8; }
.whiteLink a:hover, a.whiteLink:hover{ color: @white; opacity: 1;}

@lightBlue: #eef6fd;
.lightBlue{ color: @lightBlue;}
.lightBlueBlock{ background: @lightBlue; }
.lightBlue a, a.lightBlue{ color: @lightBlue; opacity: 0.8; }
.lightBlue a:hover, a.lightBlue:hover{ color: @white; lightBlue: 1;}

@mediumBlue: #8aaed1;
.mediumBlue{ color: @mediumBlue;}
.mediumBlueBlock{ background: @mediumBlue; }
.mediumBlue a, a.mediumBlue{ color: @mediumBlue; opacity: 0.8; }
.mediumBlue a:hover, a.mediumBlue:hover{ color: @white; mediumBlue: 1;}

@lightOrange: #ff9600;
.lightOrange{ color: @lightOrange;}
.lightOrangeBlock{ background: @lightOrange; }
.lightOrange a, a.lightOrange{ color: @lightOrange; opacity: 0.8; }
.lightOrange a:hover, a.lightOrange:hover{ color: @white; lightOrange: 1;}

@orangeLight: #ff9600;
.orangeLight{ color: @orangeLight;}
.orangeLightBlock{ background: @orangeLight; }
.orangeLight a, a.orangeLight{ color: @orangeLight; opacity: 0.8; }
.orangeLight a:hover, a.orangeLight:hover{ color: @white; orangeLight: 1;}


@orangeDark: #f07818;
.orangeDark{ color: @orangeDark;}
.orangeDarkBlock{ background: @orangeDark; }
.orangeDark a, a.orangeDark{ color: @orangeDark; opacity: 0.8; }
.orangeDark a:hover, a.orangeDark:hover{ color: @mainDarkest; orangeDark: 1;}

@twitterBg: #00aced;
.twitterBg{ background: @twitterBg;}

@fbBg: #3b5998;
.fbBg{ background: @fbBg;}

@linkedinBg: #2672ae;
.linkedinBg{ background: @linkedinBg;}


@googleRedBg: #dd4b39;
.googleRedBg{ background: @googleRedBg;}

@instagramBg: #fb3958;
.instagramBg{ background: @instagramBg; }

@greenSuccess: #3ab13a;
.greenSuccess { color: @greenSuccess;}
.greenSuccessBlock{ background: @greenSuccess;}
.greenSuccess a, a.greenSuccess{ color: @greenSuccess; opacity: 0.8; }
.greenSuccess a:hover, a.greenSuccess:hover{ color: @mainDarkest; greenSuccess: 1;}
