#page-createProjet{

    .craue_formflow_buttons{
	padding: 30px 0;
	.center;
	button{
	    .inbl;
	    margin: 5px 8px;
	    float: none;
	}
    }

    .project-image-previex {
        max-width: 450px;
    }


    a.add_counterpart_link {
        .orangeDarkBlock;
        margin-top: 20px;
    }
    label{
	.mid;
	.oswald;
	.uppercase;
    }

    form {
        h4 label {
            color: @orangeDark;
        }

        h5 label {
            color: @mainDarkest;
            .uppercase;
        }

        ul.a2lix_translationsLocales{
            li, li a {
                .oswald;
                .uppercase;
                color: @greyLight;
            }
            li.active, li.active a {
                color: @orangeDark;
            }
            li.error, li.error a {
                color: red;
            }
        }

        ul li {
            list-style-type: none;
        }

    }
    .padding-15 {
        padding: 15px;
    }

    form {
        color: @mainVeryDark;
    }

    form div .btn {
        margin: 10px 0;
    }

    form .project-image-preview img {
        max-width: 370px;
    }

    form .input-group-addon {
        color: @white;
    }
    ul.contreparties li {
        margin: 10px auto;
    }
}
