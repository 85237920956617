body {
    padding-top: 0px; /* Required padding for .navbar-fixed-top. Remove if using .navbar-static-top. Change if height of navigation changes. */
    background-color: #fff;
    font-family: 'lato';
}

#header {

}

.flx {
    display: flex;
}
.flx-center {
    align-items: center;
}
.flx-space {
    justify-content: space-between;
}
flx-nowrap {
    flex-wrap: nowrap;
}
flx-wrap {
    flex-wrap: wrap;
}

.flex-col {
    display: flex;
    display: -webkit-flex;
    flex: 1 100%;
	flex-flow: column nowrap;
}

.flex-grow {
	display: flex;
    -webkit-flex: 2;
    flex: 2;
}


/* Header Image Background - Change the URL below to your image path (example: ../images/background.jpg) */

.btn.outline {
    background: none;
}
.btn.default.outline {
    border: 2px solid #fff;
}
.navbar {
    background: none;
    /* padding-top: 16px; */
    padding-right: 20px;
}
.navbar-default {
    border: none;
}
.navbar-default .navbar-nav>li>a {
    color: #fff;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    color: #fff;
}

.navbar li a {
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    text-shadow: 0 0 5px grey;
    color: #fff;
    display: inline;
    padding: 0px;
    /* vertical-align: middle; */
}

.sprite {
    display: inline-block;
    margin: 0 0px 0 10px;
    vertical-align: middle;
}


#slogan {
    height: 217px;
    padding-top: 40px;
    background-image: url('../img/slogan-deco.png');
    background-repeat: no-repeat;
    background-position: center center;
}

.business-header {
    height: 650px;
 //   background: url('../img/heading1.jpg') center center no-repeat scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    video {
      filter: brightness(0.7);
    }
}

.search-bar {
    height: 50px;
    background-color: #ff9600;
}

/* Customize the text color and shadow color and to optimize text legibility. */

.tagline {
    text-shadow: 0 0 10px #000;
    color: #fff;

}

#welcome {
    margin-top: 50px;
    border: 1px solid #8aaed1;
    padding: 15px;
}
#welcome h2 {
    color: #535353;
}
.welcome-button {
    display: block;
    color: #535353;
    text-decoration: none;
    width: 100%;
    font-size: 18px;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #d8d8d8;
}
.welcome-button:hover {
    .orangeDarkBlock;
}

.img-center {
    margin: 0 auto;
}

/***** item *****/
.projetThumb {
    background-color: #fff;
    overflow: hidden;
    position: relative;
    border-radius: 5px;

    img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    h4 a {
        color: #535353;
    }
    a img {
        width: 100%
    }
    .item-body {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        height: 250px;
        overflow-y:auto;
        overflow-x: hidden;

        border-left: 1px solid @greyLight;
        border-right: 1px solid @greyLight;

        .push {
            flex-grow: 99;
        }

    }

    .item-footer {
        padding: 5px;
        color: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .success {
        }
    }
    .item-footer.success {
        .greenSuccessBlock;
    }
    .item-footer.closed {
        background-color: #000;
    }
    .item-footer.current {
        .orangeDarkBlock;
    }

}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th  {
    border: none;
}
.table>tbody>tr.nopadding-b>td {
    padding-bottom: 0;
}
.table>tbody>tr.nopadding-t>td {
    padding-top: 0;
}
.progress {
    overflow: visible;
    background: rgba(0,0,0,0.1);
    // -webkit-box-shadow: none;
    // -moz-box-shadow: none;
    // -o-box-shadow: none;
    box-shadow: none;
    // -webkit-border-radius: 0;
    // -moz-border-radius: 0;
    border-radius: 0;
    width: 100%;
    margin: 0;

    .progress-bar {
        float: none;
        position: relative;
    }

}

.progress.progress-xxs {
    height: 3px;
    margin-top: 8px;
}

.progress.progress-sm {
    height: 14px;
}

.carousel {
    margin: 30px auto 30px auto;
}

.owl-theme .owl-controls .owl-buttons div {
    padding: 0;
}

.controlls-over .owl-controls .owl-prev {
    position: absolute;
    top: 50%;
    margin-top: -28px;
    right:auto;
    left: 0px;
    zoom: 1;
    width: 36px;
    height: 36px;
    opacity: 0;
}
.controlls-over .owl-controls .owl-next {
    position: absolute;
    top: 50%;
    margin-top: -28px;
    left:auto;
    right: 0px;
    zoom: 1;
    width: 36px;
    height: 36px;
    opacity: 0;
}


/** home - carte **/

#carte {
    background-color: #8aaed1;
    border-top-left-radius: 700px 40px;
    border-top-right-radius: 700px 40px;
    border-bottom-left-radius: 700px 40px;
    border-bottom-right-radius: 700px 40px;
    padding: 60px 0px 50px 0px;
}
#carte, #carte h1, #carte a, #carte a:hover {
    color: #fff;
    text-decoration: none;
}
#carte a#globe {
    padding-bottom: 50px;
    font-weight: bold;
    font-size: 1.2em;
}



/**** footer  ***/
#footer {
    background-color: #5e412f;
    margin-bottom: 0px;
    padding: 15px 5px 15px 5px;
    font-family: 'Lato', sans-serif;
    font-stretch: normal;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    line-height: 18px;
    color: #fcebb6;
}
footer div.row nav {
    /* width: 25%; */
    float: left;

}


footer nav ul li a, footer nav ul li a:hover {
    color: #fcebb6;
    font-size: 15px;
    font-weight: bold;
    margin: 3px 0 3px 0;
    display: block;
    text-shadow: rgba(0, 0, 0, 0.14902) 0px 0px 1px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: transparent;

}


/**** carousel ****/

.owl-item {
    padding:  0 2px 0 2px;
}
.owl-carousel img {
    width: 98%;
    margin: 0 auto;
    display:inline-block;
}
.owl-carousel.buttons-autohide .owl-buttons {
    filter: Alpha(Opacity=0);
    opacity:0;

    -webkit-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    transition: opacity 0.4s;
}
.owl-carousel.buttons-autohide:hover .owl-buttons {
    filter: Alpha(Opacity=100);
    opacity:1;
}

.owl-theme .owl-controls .owl-buttons div {
    color:#121212;
    background: none;//#fff;
    border:none;//#fff 1px solid;

    opacity:1;
    filter: Alpha(Opacity=100);
    margin: 0px -40px 0px -40px;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.owl-prev .fa, .owl-next .fa {
    font-size: 34px;
}

/* buttons bottom */
.owl-carousel.buttons-bottom  .owl-controls{
    top:auto !important;
}
.owl-carousel.buttons-bottom .owl-next,
.owl-carousel.buttons-bottom .owl-prev {
    margin-top:-20px !important;
}


table.projet > tbody > tr > td {
    vertical-align: middle;

    strong.montant {
        font-size: calc(16px + 3vw);
    }
}


@media (max-width: 768px) {

    /* test font-size:calc(12px + 1.5vw); */
    #slogan h1 {
        font-size: 5vw;
    }
    #slogan h2 {
        font-size: 4vw;
    }

    h1 {
        font-size: calc(24px + 2vw);
    }
}


.twitter-typeahead {
    width: 100%;
}
