#page-home{
    video#bgvid {
	position: fixed;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	transform: translateX(-50%) translateY(-50%);
	background: url('../bundles/jamain/img/video/fallback.jpg') no-repeat;
	background-size: cover;
	transition: 1s opacity;
        object-fit: cover;
    }
    #slogan {

    }


    #masterslider {
        ul {
            margin: 0;
            padding: 0;
        }
    }
    searchForm, #searchForm input {
        font-size: 18px;
    }

    #projets-une {
        //background: url('../bundles/jamain/img/white-orange.png') top repeat;
        background-color: #fff;
        padding-left: 25px;
        padding-right: 25px;

        h1 {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }


    /** home - categories  **/
    #categories {
        margin-top: 50px;
        margin-bottom: 50px;
        background-color: @lightBlue;
    }

    div.categorie {
        padding: 12px 30px;
        border-radius: 15px;
    }
    div.categorie div.icon {
        margin-bottom: 5px;
        clear: both;
    }
    div.categorie, a.categorie {
        margin: 5px;
        color: @greyDark;
    }
    div.categorie:hover, div.categorie:hover a {
        background-color: #8aaed1;
        cursor: pointer; cursor: hand;
        color: #fff;
        text-decoration: none;
    }

    #home-map{
        a:hover {
            .mainDarkest;
        }
    }
    #mapbox {
        background: linear-gradient(bottom, @white 49%, @lightBlue 49%, @lightBlue 100%);

    }
    #roundmap, #map_canvas {
	border-top-left-radius: 100% 25%;
	border-top-right-radius: 100% 25%;
	border-bottom-right-radius: 100% 25%;
	border-bottom-left-radius: 100% 25%;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); /* this fixes the overflow:hidden in Chrome/Opera  */
	@media screen and (max-width: @responsiveThirdThresold){
	    -moz-border-radius: 0;
	    -webkit-border-radius: 0;
	    border-radius: 0;
            -webkit-mask-image: 0;
	}
    }

    #home-choose{
	width: 100%;
        padding: 0;
	.left{
	}
	.right{
	}
	a.left, a.right{
	    background: rgb(240, 120, 24);
	    height: 350px;
	    line-height: 350px;
	    width: 50%;
	    float: left;

	    @media screen and (max-width: @responsiveSecondThresold){
		height: 250px;
		line-height: 250px;
	    }
	    @media screen and (max-width: @responsiveThirdThresold){
		height: 200px;
		line-height: 200px;
	    }

	    .relative;
	    h3{
		font-size: 50px;
		line-height: 70px;
		width: 97%;
		top: 35px;
		margin: auto;
		line-height: normal;
		vertical-align:middle;
		display:inline-block;
		.center;
		z-index: 10;
		.relative;
		.white;

		@media screen and (max-width: @responsiveSecondThresold){
		    font-size: 30px;
		}
		@media screen and (max-width: @responsiveThirdThresold){
		    font-size: 20px;
		}
	    }
	    img{
		position: absolute;
		left: 0px;
		top: 0;
		height: 0px;
		width: 100%;
		height: 100%;
		.transition(opacity, 0.5s);
	    }
	}
	// a.left:hover, a.right:hover{
	// 	img{
	// 		opacity: 0.6
	// 	}
	// 	h3{
	// 		color: white;
	// 	}
	// }


    }
    #skymap{
	height: auto;
	background-image: url("../../img/skymap.jpg");
	background-color: #74b8fc;
	background-position: center top;
	background-repeat: no-repeat;
	background-attachment: fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
    }

    .flipLink{
	width: 12%;
	float: left;

	@media screen and (max-width: @responsiveThirdThresold){
	    // float: none;
	    width: 40%;
	    margin: 20px 5%;
	}
    }
    .slideshow{
	margin: 20px 0;
	height: 1020px;
	@media screen and (max-width: @responsiveFirstThresold){
	    height: 3050px;
	}
    }

}

.tp-video-play-button{
    display: none !important;
}

#slider-caption{
    position: absolute;
    top: 300px;
    z-index: 100;
    width: 100%;
}
#slider-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 80;
}
