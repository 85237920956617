#header{
    position: absolute;
    top: 0;
    z-index: 600;
    width: 100%;
    margin: auto;
    .white;
    .oswald;
    .uppercase;
    .mid;
    padding: 0 20px;


    ul.locale_chooser {
        margin: 0;
        .flx;
        .flx-center;
        position: absolute;
        top: 10px;
        right: 10px;
        li {
            a {
                padding: 0px 10px;
                .uppercase;
                .mediumBlueBlock;
            }
        }
    }

    @media screen and (max-width: @responsiveThirdThresold){
	position: static;
	background: @lightOrange;
	.shadowed;
	padding: 0;
	padding-bottom: 15px;
    }


    nav#header-topMenu{
	margin-top: 50px;
	float: right;
	ul{
            padding: 0;
	    li{
		float: left;
                .inbl;
		a{
		    padding: 18px 10px;
		    .white;
                    text-shadow: 0 0 10px #000;
		    opacity: 0.9;

		}
		a:hover{
		    .white;
		    opacity: 1;
		}
	    }
	}
        @media screen and (max-width: @responsiveFirstThresold){
	    float: none;
	    max-width: 600px;
	    margin: 5px auto 0;
	    ul{
		.center;
		li{
		    float: none;
		    margin: 5px;
		    .inbl;

		}
	    }
        }

        @media screen and (max-width: @responsiveSecondThresold){
	    float: none;
	    max-width: 600px;
	    margin: 5px auto 0;
	    ul{
		.center;
		li{
		    float: none;
		    margin: 5px;
		    .inbl;

		}
	    }
        }

	@media screen and (max-width: @responsiveThirdThresold){
	    display: none;
	    //max-width: 370px;
	    .center;
	    ul{
		li{
		    .inbl;
		    float: none;
		    a{
			.standard;
		    }
		}
	    }

	}
    }
    nav#header-topMenu.opened{
	@media screen and (max-width: @responsiveThirdThresold){
	    display: block;
	}
    }


    #logo{
	margin-top: 5px;
	float: left;

	@media screen and (max-width: @responsiveSecondThresold){
	    float: none;
	    margin: 0 auto;
	    width: 150px;
	    img{
		width: 100%;
	    }
	}

	@media screen and (max-width: @responsiveThirdThresold){
	    width: 220px;
	    img{
		width: 120px;
	    }
	}

    }

    #handle{
	display: none;
	@media screen and (max-width: @responsiveThirdThresold){
	    .inbl;
	    .pointer;
            position: relative;
	    top: 11px;
	    left: 20px;
	}
    }





    #headerHoverMenu{
	width: 94%;
	margin-top: 170px;
	margin: 40px auto 0;
	.whiteBlock;
	-webkit-border-top-right-radius: 100px;
	-webkit-border-bottom-left-radius: 100px;
	-moz-border-radius-topright: 100px;
	-moz-border-radius-bottomleft: 100px;
	border-top-right-radius: 100px;
	border-bottom-left-radius: 100px;
        .categorie {
            padding: 15px 0px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

	@media screen and (max-width: @responsiveSecondThresold){
	    -webkit-border-top-right-radius: 30px;
	    -webkit-border-bottom-left-radius: 30px;
	    -moz-border-radius-topright: 30px;
	    -moz-border-radius-bottomleft: 30px;
	    border-top-right-radius: 30px;
	    border-bottom-left-radius: 30px;
	}

	overflow: hidden;
	.relative;
	.shadowed;

	.transition(all, 0.4s, ease-out);
	.left{
	    .white;
	    width: 20%;
	    min-width: 171px;
	    .secondaryBlock;
	    height: 100%;
	    position: absolute;
	    -webkit-border-bottom-left-radius: 100px;
	    -moz-border-radius-bottomleft: 100px;
	    border-bottom-left-radius: 100px;
	    @media screen and (max-width: @responsiveSecondThresold){
		-webkit-border-bottom-left-radius: 30px;
		-moz-border-radius-bottomleft: 30px;
		border-bottom-left-radius: 30px;
	    }

	    nav{
		ul{
		    li{
			position: relative;
			a{
			    display: block;
			    padding: 20px 15px;
			    .white;
			    .uppercase;
			    .mid;
			    .oswald;
			    border-bottom: 1px solid fade(white, 30%);
			    @media screen and (max-width: @responsiveSecondThresold){
				.standard;
			    }
			}
			a:after{
			    position: absolute;
			    right: 15px;
			    top: 26px;
			    margin-top: -6px;
			    content: "\f105";
			    display: inline-block;
			    font-family: FontAwesome;
			    font-style: normal;
			    font-weight: 400;
			    line-height: 1;
			    .mid;
			    .bold;
			    -webkit-font-smoothing: antialiased;
			    .transition(right, 0.3s, ease-out)
			}
			a:hover{
			    background: darken(@secondary, 4%);
			}
			a:hover:after{
			    right: 5px;
			}
		    }
		}
	    }
	}
	.right{
	    margin-left: 16%;
	    .whiteBlock;
	    height: 100%;
	    padding: 1% 50px;

	    a.element{
		margin: 8px 1%;
		.inbl;
		.secondary;
		.uppercase;
		.oswald;
		width: 22.2%;
		opacity: 0.9;
		@media screen and (max-width: @responsiveSecondThresold){
		    .small;
		}
		img{
		    width: 100%;
		    margin-bottom: 4px;
		    display: block;
		}
	    }
	    a.element:hover{
		opacity: 1;
	    }
	}
    }
}

#content{
    min-height: 500px;
}

#footer{
    .mainVeryDarkBlock;
    .tertiary;
    padding-top: 30px;
    ul.locale_chooser {
        margin: 0;
        padding: 0;
        li {
            a {
                padding: 5px 10px;
                .uppercase;
                .mediumBlueBlock;
            }
            a:hover {
                .mainDarkest;
                padding: 5px 11px;
            }
        }
    }

    @media screen and (max-width: @responsiveThirdThresold){
	padding-top: 0px;
	height: auto;
    }

    h4{
	.oswald;
	.uppercase;
	.big;
	letter-spacing: 3px;
	margin-bottom: 15px;
	@media screen and (max-width: @responsiveSecondThresold){
	    .mid;
	}
    }

    nav{
	width: 25%;
	float: left;
	@media screen and (max-width: @responsiveThirdThresold){
	    float: none;
	    max-width: 200px;
	    width: auto;
	    margin: 0 auto 30px;

	    .center;
	}
	ul{
            list-style: none;
            margin: 0;
            padding: 0;
	    li{
                margin: 0;
		a{
		    .bold;
		    display: block;
		    margin: 3px 0;
		    .standard;
		    .tertiary;
		    font-size: 15px;
		}
		a:hover{
		    text-decoration: underline;;
		}
	    }
	}
    }

}


#Social-Sidebar{
    @media screen and (max-width: @responsiveThirdThresold){
	display: none;
    }
    a{
	.bold;

    }
}


#flashContainer{
    position: fixed;
    right: 0;
    top: 53px;
    max-width: 300px;
    z-index: 100;

    .notification{
	.smallShadowed;
	.relative;
    }
    .notification-red{
	.secondaryDarkBlock;
	.white;
    }
    .notification-green{
	.secondaryDarkBlock;
	.white;
    }
    .close{
	.bold;
	.white;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
    }
}
