#page-conditionsGenerales{
    mark {
        background: yellow;
    }

    mark.current {
        background: orange;
    }

    .header {
        padding: 10px;
        width: 100%;
        background: @lightBlue;
        top: 0;
        left: 0;
    }
    .header .searchbox {
        margin-left: auto;
        margin-right: 30px;
    }

    section{
        padding: 30px;
        .centeredContainer{
            .hugePadding;
        }
        h2{
            font-size: 30px;
            font-family:
            .bold;
            margin: 25px 0;
            position: relative;
            right: 27px;
            i{
                position: relative;
                right: 30px;
            }
        }

        h3{
            .mid;
            .bold;
            margin: 15px 0;
            .pointer;
        }
        .container{
            margin-bottom: 30px;
            .mid;
            display: none;
        }
        .container-show{
            margin-bottom: 30px;
            .mid;
        }
    }
}
