body{
    background: @tertiary;
    color: @mainVeryDark;
}


#searchForm{
    input:not([type="submit"]),
    textarea,
    .select,
    select
    {
	padding: 10px 15px;
	height: auto;
	font-size: 32px;
	line-height: 40px;
	color: white;
	background: transparent;
	border: none;

	@media screen and (max-width: @responsiveThirdThresold){
	    font-size: 19px;
	    line-height: 25px;
	}
    }

    button{
	border: none;
	background: transparent;
	opacity: 0.9;
	padding: 0;
    }
    button:hover{
	border: none;
	background: transparent;
	opacity: 1;
    }

    input:not([type="submit"])::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder,
    .select{
	color: white;
    }

    input:not([type="submit"]):hover,
    textarea:hover,
    .select:hover,
    select:hover
    {
	border: none;
	background: transparent;
    }
    input:not([type="submit"]):focus,
    textarea:focus,
    .select:focus,
    select:focus
    {
	border: none;
	background: transparent;
    }
}


.skymap{
    background: url("../img/skymap.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center 50%;
    background-size: cover;
    width: 100%;
}


.projetThumbContainer{
    .smallPadding;
    width: 33%;
    float: left;
    margin-bottom: 20px;

    @media screen and (max-width: @responsiveFirstThresold){
	float: none;
	width: auto;
	max-width: 300px;
	margin: 10px auto;
    }

    .projetThumb{
	-webkit-border-top-right-radius: 100px;
	-webkit-border-bottom-left-radius: 100px;
	-moz-border-radius-topright: 100px;
	-moz-border-radius-bottomleft: 100px;
	border-top-right-radius: 100px;
	border-bottom-left-radius: 100px;
	overflow: hidden;
	-webkit-transition: -webkit-transform 0.3s ease-out; /* Changed here */
	-moz-transition: -moz-transform 0.3s ease-out;
	-o-transition: -o-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	width: 351px;
	height: 474px;

	.projetThumb-image{
	    height: 186px;
	    max-width: 100%;
	    overflow: hidden;
	    img{
	    }
	}
	.projetThumb-nom{
	    .mainBlock;
	    .white;
	    .uppercase;
	    .center;
	    .em(16px);
	    font-weight: 700;
	    padding: 10px;
	}
	.projetThumb-tab{
	    margin-bottom: 10px;
	    .bold;
	}

	.projetThumb-numbers{
	    margin: 20px 10px 10px;
	    .part1, .part2, .part3{
		.floatLeft;
		min-width: 33%;
		.bold;
	    }
	    .part1{
		.mid;
		img{
		    vertical-align: middle;
		}
	    }
	    .part2{
		.latoLight;
		.small;
	    }
	    .part3{
		.latoLight;
		.small;
	    }
	}
	p.description{
	    height: 61px;
	    line-height: 21px;
	    overflow: hidden;
	}
    }

    .projetThumb:hover{
	-webkit-transform: translateY(-8px);
	transform: translateY(-8px);
    }
}



.curlyBack{
    background: url('../bundles/jamain/img/curly.gif');
    z-index: 10;
}
.roundTop{
    background-repeat: repeat;
    background-size: auto auto;
    height: 80px;
    padding: 0;
    position: relative;
    background-repeat: repeat;
    -webkit-background-size: auto auto;
    background-size: auto auto;
    overflow: hidden;
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    background-position:  0 -70px;
}
.roundBottom{
    background-repeat: repeat;
    background-size: auto auto;
    height: 80px;
    padding: 0;
    //position: relative;
    background-repeat: repeat;
    -webkit-background-size: auto auto;
    background-size: auto auto;
    overflow: hidden;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 50% 100%;
    border-bottom-left-radius: 50% 100%;
    background-position:  0 -77px;
    bottom: 10px;
}



ol.rounded-list{
    counter-reset: li;
    .big;
    li{
	position: relative;
	margin: 40px 0;
    }
    li:before{
	counter-increment: li;
	content: counter(li);
	position: absolute;
	left: -80px;
	top: 52%;
	margin-top: -1.3em;
	height: 60px;
	width: 60px;
	line-height: 2em;
	border: .3em solid #f07818;
	text-align: center;
	font-weight: bold;
	border-radius: 2em;
	transition: all .3s ease-out;
    }
    li:hover:before{
	-ms-transform: rotate(360deg); /* IE 9 */
    	-webkit-transform: rotate(360deg); /* Chrome, Safari, Opera */
    	transform: rotate(360deg);
    }
}

.bordered(@width : 1px){
    padding: 8px 30px;
    color: #fff !important;
    border: @width solid #fff;

    text-align: center;
    outline: none;
    text-decoration: none;
    font-size: 20px;
    line-height: 30px;

    .oswald;
    .uppercase;
}


.curled{
    background: url("../bundles/jamain/img/topBottomCurl.png") no-repeat;
    width: 135px;
    height: 173px;
    .inbl;
    .white;
    padding-top: 50px;
    margin: 0 18px;
}


.skyTitle{
    width: 100%;
    background-image: url("../bundles/jamain/img/sky_bg.png");
    background-position: 0px 0px;
    background-repeat: repeat-x;
    -webkit-animation: clouds 60s linear infinite;
    -moz-animation: clouds 60s linear infinite;
    -ms-animation: clouds 60s linear infinite;
    -o-animation: clouds 60s linear infinite;
    animation: clouds 60s linear infinite;
    height: 200px;
    .center;
    .white;
    .no-cpu;
    padding-top: 120px;
    h1{
	.huge;
	.uppercase;
	.oswald;
	text-shadow:  0 5px 7px #7b9cbb;
    }

    @media screen and (max-width: @responsiveSecondThresold){
	height: 200px;
	padding-top: 140px;
    }
    @media screen and (max-width: @responsiveThirdThresold){
	height: 90px;
	padding-top: 20px;
        h1 {
            font-size: calc(18px + 2vw);
        }
    }
}


.contactForm{

    input:not([type="submit"]),
    textarea,
    .select,
    select
    {
	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	border-bottom: 2px solid @mainVeryDark !important;
	background: transparent;
	color: @mainVeryDark;
	padding: 20px 7px;
	font-size: 22px;
	line-height: 36px;
	margin: 20px 0;
    }

    textarea, textarea:hover{
	height: 250px;
	border-bottom: 2px solid @mainVeryDark !important;
    }

    input:not([type="submit"])::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder,
    .select{
	color: fade(@mainVeryDark, 50%);
    }


    input:not([type="submit"]):hover,
    textarea:hover,
    .select:hover,
    select:hover
    {
	border-top: 1px solid transparent;
	border-left: 1px solid transparent;
	border-right: 1px solid transparent;
	border-bottom: 2px solid @mainVeryDark !important;
	background: transparent;
	color: @mainVeryDark;
    }
    input:not([type="submit"]):focus,
    textarea:focus,
    .select:focus,
    select:focus
    {
	border-top: 0 none;
	border-left: 0 none;
	border-right: 0 none;
	border-bottom: 2px solid @mainVeryDark;
	background: transparent;
	color: @mainVeryDark;
    }


}


.social{
    font-family: "Social-Icons";
    > a{
	color: red;
	width: 35px;
	height: 35px;
	line-height: 40px;
	font-size: 25px;
	text-align: center;
	position: relative;
	display: inline-block;
	margin: 5px;
	vertical-align: middle;

    }
    .fb:before{
	color: #3b5998;
	content: "\e61c";
    }
    .twitter:before{
	color: #00aced;
	content: "\e618";
    }
    .google:before{
	color: #dd4b39;
	content: "\e605";
    }
    .linkedIn:before{
	color: #007BB6;
	content: "\e632";
    }
    .pinterest:before{
	color: #d53939;
	content: "\e647";
    }
    a:before{
	.transition(all);
    }
    a:hover:before{
	color: #565656;
    }
}

.slideBlock{
    background: @mainVeryDark;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    .block;
    padding: 10px 20px 6px;
    margin: 15px 0 15px 15px;
    .tertiary;
    .title{
	.uppercase;
	.oswald;
	font-size: 19px;
    }
}
a.slideBlock:hover{
    color: @secondary;
}


.errorTooltip{
    background: #8E0000;
    .white;
    .smallPadding;
    position: absolute;
    bottom: 102%;
    z-index: 20;
    display: none;
    width: 300px;
}


.jaTable{
    tr{
	border-top: 1px solid fade(@mainVeryDark, 20%);
    }
    th, td{
	vertical-align: middle;
    }
}


.topMenu{
    background: white;
    .uppercase;
    .secondary;
    .center;
    margin-bottom: 20px;


    li{
	margin: 0;
	.inbl;

	//line-height: 32px; // disabled, just let the golden ratio rule over this
	//.mid;
	.bold;
	> a, > span{
	    display: block;
	    padding: 20px 15px;
	    color: @mediumBlue;
	}
	> a:hover{
	    color: @orangeLight;
	}
    }

    li.current, li.craue_formflow_current_step{
	> a, > span{
	    background: @orangeDark;
	    color: @white;
	    padding: 20px 15px 23px;
	}
    }
    li.craue_formflow_skipped_step {
	text-decoration: line-through;
    }

}


.bigLink{
    @height: 300px;
    position: relative;
    height: @height;
    overflow: hidden;
    .whiteBlock;
    .secondary;
    display: block;
    .center;

    img{
	height: 100px;
    }

    .link{
	position: absolute;
	height: @height;
	width: 100%;
	// opacity: 0;
	z-index: 10;
	.mainBlock;
	.white;
	.center;
	.big;
	.oswald;
	.uppercase;
	line-height: @height;
	opacity: 0;
	.transition(opacity);

    }
}

.bigLink:hover{
    .link{
	opacity: 0.9;
    }
}

.projetCategorie{
    .inbl;
    .mainVeryDark;
    .bold;
    width: 12.1%;
    .center;
    margin: 15px 0;

    @media screen and (max-width: @responsiveSecondThresold){
	width: 24.2%;
    }
    @media screen and (max-width: @responsiveThirdThresold){
	width: 49%;
    }
}
