#page-projet{

    color: @greyDark;

    background: url("../../bundles/jamain/img/projet/white-blue.png");

    img {
        max-width: 720px;

	@media screen and (max-width: @responsiveFirstThresold){
	    max-width: 90%;
	}
	@media screen and (max-width: @responsiveSecondThresold){
	    max-width: 90%;
	}
    }
    .video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
    }

    .video-container iframe,
    .video-container object,
    .video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    }

    ul.nav.nav-tabs {
        border-bottom: none;
    }
    ul.nav.nav-tabs.nav-top-border {
        li {
            border-top: 3px solid @greyLight;
            a {
                border-radius: 0;
                margin: 0;
                border: 0;
                background-color: #fff;
                color: @greyDark;
            }
            line-height: 19px;
        }
        li.active {
            border-top-color: @lightOrange;
            line-height: 19px;
        }
        li:hover {
            border-top-color: @mediumBlue;
        }

    }

    .tab-pane {
        .whiteBlock;
        img {
            max-width: 720px;
	    @media screen and (max-width: @responsiveFirstThresold){
	        max-width: 640px;
	    }
	    @media screen and (max-width: @responsiveSecondThresold){
	       // max-width: 480px;
	    }
        }
        padding: 20px;
    }

    .counterpart {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-radius: 5px;
    }

    .counterpart .overbox {
        border-radius: 5px;
        background-color: @mediumBlue;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff;
        z-index: 100;
        transition: all 300ms ease-out;
        opacity: 0;
        width: 100%;
        height: 100%;
        padding: 20px;
    }

    // css herefrom : http://www.cssscript.com/pure-css3-animated-text-overlay-on-hover/
    .counterpart:hover .overbox { opacity: 0.98; }

    .counterpart .overtext {
        transition: all 300ms ease-out;
        transform: translateY(40px);
    }

    .counterpart .title {
        font-size: 2.5em;
        text-transform: uppercase;
        opacity: 0;
        transition-delay: 0.1s;
        transition-duration: 0.2s;
    }

    .counterpart:hover .title, .counterpart:focus .title {
        opacity: 1;
        transform: translateY(0px);
    }
}
