#page-blog-article{

    .blogTitle {
        background-size: cover;
        height: 600px;

//        vertical-align: middle;
        h1{
	    .huge;
	    .uppercase;
	    .oswald;
	    text-shadow:  0 5px 7px #7b9cbb;
        }
    }
    img {
        max-width: 640px;
        display: block;
        margin: 0 auto;
    }
}
