#page-landing{
	.relative;
	height: 100%;
	width: 100%;
	background-color: blue;
	z-index: 1;

	.overlay{
		background: fade(#0C79D3, 45%);
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
		top: 0;
		left: 0;
	}

	> .centeredContainer{
		position: relative;
		z-index: 3;
	}
}