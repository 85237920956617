

#page-rejoignezAventure{
	#parallax-1{
		background-image: url("../../bundles/jamain/img/rejoignezAventure/parallax1.jpg");
		height: 700px;
	}
	#parallax-2{
		background-image: url("../../bundles/jamain/img/rejoignezAventure/parallax2.jpg");
		height: 600px;
	}
	#parallax-3{
		background-image: url("../../bundles/jamain/img/rejoignezAventure/parallax3.jpg");
		height: 600px;
	}
	#parallax-4{
		background-image: url("../../bundles/jamain/img/rejoignezAventure/parallax4.jpg");
		height: 600px;
	}

	#thumb{
		background: @mainVeryDark;
		color: @tertiary;
		font-size: 60px;
		.rounded(100px);
		padding: 40px;
		.inbl;
	}
}
