.transition (@target : all, @length: @hoverTransitionsDuration, @ease: ease){
	-webkit-transition: @target @length @ease;
	-moz-transition: @target @length @ease;
	-o-transition: @target @length @ease;
	transition: @target @length @ease;
}

.truncate (@width : auto){
	width: @width;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	-ms-text-overflow: ellipsis; 

}


.limitLines(@nbLines, @lineHeight){
	height: @nbLines * @lineHeight;
	overflow: hidden;
}

@-webkit-keyframes fadeIn {
	0% {
		display:none; 
		opacity: 0;
	}

	1% {
		display: block ; 
		opacity: 0;
	}

	100% {
		display: block ; 
		opacity: 1;
	}
}

@base-font-size: 16px*0.8;
.em(@target, @context: @base-font-size) {
	font-size: @target / @context + 0em;
}