.icon{
	background-image: url("../img/icons.png");
	background-repeat: no-repeat;
	background-position: top left;
	display: inline-block;
	vertical-align: middle;
}

.icon-downArrow{
	.icon;
	background-position: 0px 0px;
	height: 20px;
	width: 20px;
}

.icon-whiteTopArrow{
	.icon;
	background-position: 0px -39px;
	height: 44px;
	width: 44px;
}

.icon-plus{
	.icon;
	background-position: 0px -103px;
	height: 33px;
	width: 33px;
}
.icon-file{
	.icon;
	background-position: 0px -174px;
	height: 35px;
	width: 35px;
}
.icon-question{
	.icon;
	background-position: 0px -234px;
	height: 32px;
	width: 33px;
}

.icon-creditCard{
	.icon;
	background-position: 0px -320px;
	height: 20px;
	width: 29px;
}

.icon-creditCardAct{
	.icon;
	background-position: 0px -280px;
	height: 20px;
	width: 29px;
}

.icon-cheque{
	.icon;
	background-position: 0px -404px;
	height: 21px;
	width: 28px;
}


.icon-chequeAct{
	.icon;
	background-position: 0px -364px;
	height: 21px;
	width: 28px;
}

.icon-profil{
	.icon;
	background-position: -1px -438px;
	height: 23px;
	width: 23px;
}

.icon-parametres{
	.icon;
	background-position: 0px -474px;
	height: 11px;
	width: 12px;
}


.icon-close{
	.icon;
	background-position: 0px -502px;
	height: 14px;
	width: 15px;
	.pointer;
}

.icon-search{
	.icon;
	background-position: 0px -502px;
	height: 16px;
	width: 16px;
}

.icon-createFolder{
	.icon;
	background-position: 0px -706px;
	height: 24px;
	width: 24px;
}


.icon-deposerFichier{
	.icon;
	background-position: 0px -536px;
	height: 26px;
	width: 26px;
}


.icon-whiteEmail{
	.icon;
	background-position: 0px -767px;
	height: 21px;
	width: 21px;
}

.icon-whitePrint{
	.icon;
	background-position: 0px -819px;
	height: 21px;
	width: 21px;
}

.icon-whiteDownload{
	.icon;
	background-position: 0px -866px;
	height: 21px;
	width: 21px;
}

.icon-whiteDelete{
	.icon;
	background-position: 0px -908px;
	height: 21px;
	width: 21px;
}

.icon-edit{
	.icon;
	background-position: 0px -940px;
	height: 18px;
	width: 18px;
}


.icon-addUser{
	.icon;
	background-position: 0px -968px;
	height: 24px;
	width: 26px;
}

.icon-otherProfile{
	.icon;
	background-position: 0px -1014px;
	height: 22px;
	width: 27px;
}


.icon-beneficiaire{
	.icon;
	background-position: 0px -1006px;
	height: 30px;
	width: 33px;
}

.icon-membre{
	.icon;
	background-position: 0px -1116px;
	height: 30px;
	width: 33px;
}

.icon-paiement{
	.icon;
	background-position: 0px -395px;
	height: 30px;
	width: 33px;
}


.icon-beneficiaire.act{
	.icon;
	background-position: 0px -1056px;
	height: 30px;
	width: 33px;
}

.icon-membre.act{
	.icon;
	background-position: 0px -1169px;
	height: 30px;
	width: 33px;
}

.icon-paiement.act{
	.icon;
	background-position: 0px -356px;
	height: 30px;
	width: 33px;
}



.icon-parametresGrey{
	.icon;
	background-position: 0px -1220px;
	height: 11px;
	width: 12px;
}