#page-laPetiteHistoire{
    background: url("../../bundles/jamain/img/laPetiteHistoire/bg.gif") repeat scroll 0 0 #8aaed1;
    -webkit-animation: clouds 60s linear infinite;
    -moz-animation: clouds 60s linear infinite;
    -ms-animation: clouds 60s linear infinite;
    -o-animation: clouds 60s linear infinite;
    animation: clouds 60s linear infinite;
    min-height: 500px;


    .timeline {
	position : relative;
	overflow : auto;
    }
    .timeline:before {
	background : none repeat scroll 0 0 #fff;
	content    : "";
	height     : 100%;
	left       : 0;
	position   : absolute;
	width      : 7px;
    }
    .timeline h2 {
	background : none repeat scroll 0 0 #f07818;
	clear      : both;
	color      : white;
	font-size  : 25px;
	margin     : 0 auto 50px;
	max-width  : 6em;
	padding    : 10px;
	position   : relative;
	text-align : center;
    }
    .timeline h3 {
	color          : #f07818;
	font-size      : 20px;
	font-family    : 'oswald';
	margin-top     : 0;
	padding        : 11px;
	text-transform : uppercase;
    }
    .timeline p {
	padding    : 15px 15px;
	font-style : italic;
    }
    .timeline ul {
	list-style : none;
	padding    : 0 0 0 1em;
	z-index    : 1;
    }
    .timeline li {
	background : none repeat scroll 0 0 #fff;
	margin     : 0 0 18px;
	padding    : 1em;
	position   : relative;
    }
    .timeline li:before {
	content     : '';
	width       : 0;
	height      : 0;
	border-top  : 1em solid #fff;
	border-left : 1em solid transparent;
	position    : absolute;
	left        : -1em;
	top         : 0;
    }
    .timeline time {
	color          : #8aaed1;
	display        : block;
	font-weight    : bold;
	text-align     : right;
	text-transform : uppercase;
	width          : 100%;
    }
    .timeline img {
        max-width: 370px;
        max-width:90% !important;
        height:auto !important;
        margin: 5px auto;

    }
    @media screen and (min-width : 1160px) {
        /*.container {
	max-width: 1000px;
	margin: 0 auto;
	}*/
	.timeline:before {
	    left : 49.6%;
	}
	.timeline ul {
	    padding-left : 0;
	    margin       : 0 auto;
	}
	.timeline li {
	    width : 43%;
	}
	.timeline li:nth-child(even) {
	    float      : right;
	    margin-top : 5em;
	}
	.timeline li:nth-child(odd) {
	    float : left;
	}
	.timeline li:nth-child(odd):before {
	    border-top   : 1em solid #fff;
	    border-right : 1em solid transparent;
	    right        : -1em;
	    left         : auto;
	}
	.timeline li:nth-of-type(2n+1) {
	    clear : both;
	}
    }
}
