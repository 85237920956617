@-webkit-keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block ;
    opacity: 0;
  }
  100% {
    display: block ;
    opacity: 1;
  }
}
.main {
  color: #fff;
}
.mainBlock {
  background: #fff;
}
.mainLink a,
a.mainLink {
  color: #fff;
  opacity: 0.8;
}
.mainLink a:hover,
a.mainLink:hover {
  color: #fff;
  opacity: 1;
}
.mainLight {
  color: #F9974F;
}
.mainLightBlock {
  background: #F9974F;
}
.mainLightLink a,
a.mainLightLink {
  color: #F9974F;
  opacity: 0.8;
}
.mainLightLink a:hover,
a.mainLightLink:hover {
  color: #F9974F;
  opacity: 1;
}
.mainLightest {
  color: #FCC196;
}
.mainLightestBlock {
  background: #FCC196;
}
.mainLightestLink a,
a.mainLightestLink {
  color: #FCC196;
  opacity: 0.8;
}
.mainLightestLink a:hover,
a.mainLightestLink:hover {
  color: #FCC196;
  opacity: 1;
}
.mainVeryDark {
  color: #5e412f;
}
.mainVeryDarkBlock {
  background: #5e412f;
}
.mainVeryDarkLink a,
a.mainVeryDarkLink {
  color: #5e412f;
  opacity: 0.8;
}
.mainVeryDarkLink a:hover,
a.mainVeryDarkLink:hover {
  color: #5e412f;
  opacity: 1;
}
.mainDark {
  color: #afa097;
}
.mainDarkBlock {
  background: #afa097;
}
.mainDarkLink a,
a.mainDarkLink {
  color: #afa097;
  opacity: 0.8;
}
.mainDarkLink a:hover,
a.mainDarkLink:hover {
  color: #afa097;
  opacity: 1;
}
.mainDarkest {
  color: #4f2f1b;
}
.mainDarkestBlock {
  background: #4f2f1b;
}
.mainDarkestLink a,
a.mainDarkestLink {
  color: #4f2f1b;
  opacity: 0.8;
}
.mainDarkestLink a:hover,
a.mainDarkestLink:hover {
  color: #4f2f1b;
  opacity: 1;
}
.secondary {
  color: #8AAED1;
}
.secondaryBlock {
  background: #8AAED1;
}
.secondaryLink a,
a.secondaryLink {
  color: #8AAED1;
  opacity: 0.8;
}
.secondaryLink a:hover,
a.secondaryLink:hover {
  color: #8AAED1;
  opacity: 1;
}
.secondaryDark {
  color: #457AAF;
}
.secondaryDarkBlock {
  background: #457AAF;
}
.secondaryDarkLink a,
a.secondaryDarkLink {
  color: #457AAF;
  opacity: 0.8;
}
.secondaryDarkLink a:hover,
a.secondaryDarkLink:hover {
  color: #457AAF;
  opacity: 1;
}
.tertiary {
  color: #fcebb6;
}
.tertiaryBlock {
  background: #fcebb6;
}
.tertiaryLink a,
a.tertiaryLink {
  color: #fcebb6;
  opacity: 0.8;
}
.tertiaryLink a:hover,
a.tertiaryLink:hover {
  color: #fcebb6;
  opacity: 1;
}
.greyLightest {
  color: #eeeeee;
}
.greyLightestBlock {
  background: #eeeeee;
}
.greyLightestLink a,
a.greyLightestLink {
  color: #eeeeee;
  opacity: 0.8;
}
.greyLightestLink a:hover,
a.greyLightestLink:hover {
  color: #eeeeee;
  opacity: 1;
}
.greyLight {
  color: #aaa;
}
.greyLightBlock {
  background: #aaa;
}
.greyLightLink a,
a.greyLightLink {
  color: #aaa;
  opacity: 0.8;
}
.greyLightLink a:hover,
a.greyLightLink:hover {
  color: #aaa;
  opacity: 1;
}
.grey {
  color: #707070;
}
.greyBlock {
  background: #707070;
}
.greyLink a,
a.greyLink {
  color: #707070;
  opacity: 0.8;
}
.greyLink a:hover,
a.greyLink:hover {
  color: #707070;
  opacity: 1;
}
.greyDark {
  color: #535353;
}
.greyDarkBlock {
  background: #535353;
}
.greyDarkLink a,
a.greyDarkLink {
  color: #535353;
  opacity: 0.8;
}
.greyDarkLink a:hover,
a.greyDarkLink:hover {
  color: #535353;
  opacity: 1;
}
.greyDarkest {
  color: #36322f;
}
.greyDarkestBlock {
  background: #36322f;
}
.greyDarkestLink a,
a.greyDarkestLink {
  color: #36322f;
  opacity: 0.8;
}
.greyDarkestLink a:hover,
a.greyDarkestLink:hover {
  color: #36322f;
  opacity: 1;
}
.black {
  color: #000;
}
.blackBlock {
  background: #000;
}
.blackLink a,
a.blackLink {
  color: #000;
  opacity: 0.8;
}
.blackLink a:hover,
a.blackLink:hover {
  color: #000;
  opacity: 1;
}
.white {
  color: #fff;
}
.whiteBlock {
  background: #fff;
}
.whiteLink a,
a.whiteLink {
  color: #fff;
  opacity: 0.8;
}
.whiteLink a:hover,
a.whiteLink:hover {
  color: #fff;
  opacity: 1;
}
.lightBlue {
  color: #eef6fd;
}
.lightBlueBlock {
  background: #eef6fd;
}
.lightBlue a,
a.lightBlue {
  color: #eef6fd;
  opacity: 0.8;
}
.lightBlue a:hover,
a.lightBlue:hover {
  color: #fff;
  lightBlue: 1;
}
.mediumBlue {
  color: #8aaed1;
}
.mediumBlueBlock {
  background: #8aaed1;
}
.mediumBlue a,
a.mediumBlue {
  color: #8aaed1;
  opacity: 0.8;
}
.mediumBlue a:hover,
a.mediumBlue:hover {
  color: #fff;
  mediumBlue: 1;
}
.lightOrange {
  color: #ff9600;
}
.lightOrangeBlock {
  background: #ff9600;
}
.lightOrange a,
a.lightOrange {
  color: #ff9600;
  opacity: 0.8;
}
.lightOrange a:hover,
a.lightOrange:hover {
  color: #fff;
  lightOrange: 1;
}
.orangeLight {
  color: #ff9600;
}
.orangeLightBlock {
  background: #ff9600;
}
.orangeLight a,
a.orangeLight {
  color: #ff9600;
  opacity: 0.8;
}
.orangeLight a:hover,
a.orangeLight:hover {
  color: #fff;
  orangeLight: 1;
}
.orangeDark {
  color: #f07818;
}
.orangeDarkBlock {
  background: #f07818;
}
.orangeDark a,
a.orangeDark {
  color: #f07818;
  opacity: 0.8;
}
.orangeDark a:hover,
a.orangeDark:hover {
  color: #4f2f1b;
  orangeDark: 1;
}
.twitterBg {
  background: #00aced;
}
.fbBg {
  background: #3b5998;
}
.linkedinBg {
  background: #2672ae;
}
.googleRedBg {
  background: #dd4b39;
}
.instagramBg {
  background: #fb3958;
}
.greenSuccess {
  color: #3ab13a;
}
.greenSuccessBlock {
  background: #3ab13a;
}
.greenSuccess a,
a.greenSuccess {
  color: #3ab13a;
  opacity: 0.8;
}
.greenSuccess a:hover,
a.greenSuccess:hover {
  color: #4f2f1b;
  greenSuccess: 1;
}
/* Fonts */
@font-face {
  font-family: 'latoLight';
  src: url('../fonts/lato/Lato-Light.eot');
  src: url('../fonts/lato/Lato-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/Lato-Light.woff2') format('woff2'), url('../fonts/lato/Lato-Light.woff') format('woff'), url('../fonts/lato/Lato-Light.ttf') format('truetype'), url('../fonts/lato/Lato-Light.svg#asapbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lato';
  src: url('../fonts/lato/Lato-Regular.eot');
  src: url('../fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/Lato-Regular.woff2') format('woff2'), url('../fonts/lato/Lato-Regular.woff') format('woff'), url('../fonts/lato/Lato-Regular.ttf') format('truetype'), url('../fonts/lato/Lato-Regular.svg#asapbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lato';
  src: url('../fonts/lato/Lato-Bold.eot');
  src: url('../fonts/lato/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/Lato-Bold.woff2') format('woff2'), url('../fonts/lato/Lato-Bold.woff') format('woff'), url('../fonts/lato/Lato-Bold.ttf') format('truetype'), url('../fonts/lato/Lato-Bold.svg#asapbold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'latoBlack';
  src: url('../fonts/lato/Lato-Black.eot');
  src: url('../fonts/lato/Lato-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/Lato-Black.woff2') format('woff2'), url('../fonts/lato/Lato-Black.woff') format('woff'), url('../fonts/lato/Lato-Black.ttf') format('truetype'), url('../fonts/lato/Lato-Black.svg#asapbold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'oswaldLight';
  src: url('../fonts/oswald/Oswald-Light-webfont.eot');
  src: url('../fonts/oswald/Oswald-Light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/oswald/Oswald-Light-webfont.woff2') format('woff2'), url('../fonts/oswald/Oswald-Light-webfont.woff') format('woff'), url('../fonts/oswald/Oswald-Light-webfont.ttf') format('truetype'), url('../fonts/oswald/Oswald-Light-webfont.svg#asapbold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'oswald';
  src: url('../fonts/oswald/Oswald-Bold-webfont.eot');
  src: url('../fonts/oswald/Oswald-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/oswald/Oswald-Bold-webfont.woff2') format('woff2'), url('../fonts/oswald/Oswald-Bold-webfont.woff') format('woff'), url('../fonts/oswald/Oswald-Bold-webfont.ttf') format('truetype'), url('../fonts/oswald/Oswald-Bold-webfont.svg#asapbold_italic') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'oswald';
  src: url('../fonts/oswald/Oswald-Regular-webfont.eot');
  src: url('../fonts/oswald/Oswald-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/oswald/Oswald-Regular-webfont.woff2') format('woff2'), url('../fonts/oswald/Oswald-Regular-webfont.woff') format('woff'), url('../fonts/oswald/Oswald-Regular-webfont.ttf') format('truetype'), url('../fonts/oswald/Oswald-Regular-webfont.svg#asapitalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
.oswald {
  letter-spacing: 1px;
  font-family: "oswald";
}
.oswaldLight {
  font-family: "oswaldLight";
}
.lato {
  font-family: "lato";
}
.latoLight {
  font-family: "latoLight";
}
.latoBlack {
  font-family: "latoBlack";
}
.bold {
  font-weight: bold;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "lato";
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
}
/* Variables */
/* Design Rules */
html {
  font-size: 100%;
}
body {
  font-size: 0.8em;
  line-height: 1.6;
  color: #36322f;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke: 1px transparent;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
}
.centeredContainer {
  max-width: 1152px;
  margin: auto;
}
.no-cpu {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
}
@media screen and (max-width: 1200px) {
  .centeredContainer {
    padding: 0 12px;
  }
}
.giant {
  font-size: 3.75em;
}
.huge {
  font-size: 2.5em;
}
.big {
  font-size: 1.953125em;
}
.mid {
  font-size: 1.328125em;
}
.standard {
  font-size: 1.015625em;
}
.small {
  font-size: 0.9375em;
}
.tiny {
  font-size: 0.859375em;
}
.verytiny {
  font-size: 0.78125em;
}
.uppercase {
  text-transform: uppercase;
}
.block {
  display: block;
}
.h1 {
  font-size: 60px;
  line-height: 80px;
  font-weight: bold;
}
.h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}
.noTextStroke {
  -webkit-text-stroke: 0 !important;
}
hr {
  display: block;
  border: 0;
  border-top: 1px solid #aaa;
  margin: 1em 0;
  width: 100%;
  padding: 0;
}
hr.bigHr {
  border-bottom: 2px solid #707070;
  width: 100%;
  height: 6px;
  background: #fff;
}
.insetShadow {
  color: rgba(13, 12, 11, 0.8);
  text-shadow: 1px 1px 4px #eee, 0 0 0 #000, 1px 1px 4px #eee;
}
.insetShadow::-moz-selection {
  background: #5af;
  color: #fff;
  text-shadow: none;
}
.insetShadow::selection {
  background: #5af;
  color: #fff;
  text-shadow: none;
}
.span1 {
  float: left;
  width: 38px;
  margin-left: 5px;
  margin-right: 5px;
}
.span2 {
  float: left;
  width: 86px;
  margin-left: 5px;
  margin-right: 5px;
}
.span3 {
  float: left;
  width: 134px;
  margin-left: 5px;
  margin-right: 5px;
}
.span4 {
  float: left;
  width: 182px;
  margin-left: 5px;
  margin-right: 5px;
}
.span5 {
  float: left;
  width: 230px;
  margin-left: 5px;
  margin-right: 5px;
}
.span6 {
  float: left;
  width: 278px;
  margin-left: 5px;
  margin-right: 5px;
}
.span7 {
  float: left;
  width: 326px;
  margin-left: 5px;
  margin-right: 5px;
}
.span8 {
  float: left;
  width: 374px;
  margin-left: 5px;
  margin-right: 5px;
}
.span9 {
  float: left;
  width: 422px;
  margin-left: 5px;
  margin-right: 5px;
}
.span10 {
  float: left;
  width: 470px;
  margin-left: 5px;
  margin-right: 5px;
}
.span11 {
  float: left;
  width: 518px;
  margin-left: 5px;
  margin-right: 5px;
}
.span12 {
  float: left;
  width: 566px;
  margin-left: 5px;
  margin-right: 5px;
}
.span13 {
  float: left;
  width: 614px;
  margin-left: 5px;
  margin-right: 5px;
}
.span14 {
  float: left;
  width: 662px;
  margin-left: 5px;
  margin-right: 5px;
}
.span15 {
  float: left;
  width: 710px;
  margin-left: 5px;
  margin-right: 5px;
}
.span16 {
  float: left;
  width: 758px;
  margin-left: 5px;
  margin-right: 5px;
}
.span17 {
  float: left;
  width: 806px;
  margin-left: 5px;
  margin-right: 5px;
}
.span18 {
  float: left;
  width: 854px;
  margin-left: 5px;
  margin-right: 5px;
}
.span19 {
  float: left;
  width: 902px;
  margin-left: 5px;
  margin-right: 5px;
}
.span20 {
  float: left;
  width: 950px;
  margin-left: 5px;
  margin-right: 5px;
}
.span21 {
  float: left;
  width: 998px;
  margin-left: 5px;
  margin-right: 5px;
}
.span22 {
  float: left;
  width: 1046px;
  margin-left: 5px;
  margin-right: 5px;
}
.span23 {
  float: left;
  width: 1094px;
  margin-left: 5px;
  margin-right: 5px;
}
.span24 {
  float: left;
  width: 1142px;
  margin-left: 5px;
  margin-right: 5px;
}
.span1.noMargin {
  float: left;
  width: 38px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span2.noMargin {
  float: left;
  width: 86px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span3.noMargin {
  float: left;
  width: 134px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span4.noMargin {
  float: left;
  width: 182px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span5.noMargin {
  float: left;
  width: 230px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span6.noMargin {
  float: left;
  width: 278px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span7.noMargin {
  float: left;
  width: 326px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span8.noMargin {
  float: left;
  width: 374px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span9.noMargin {
  float: left;
  width: 422px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span10.noMargin {
  float: left;
  width: 470px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span11.noMargin {
  float: left;
  width: 518px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span12.noMargin {
  float: left;
  width: 566px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span13.noMargin {
  float: left;
  width: 614px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span14.noMargin {
  float: left;
  width: 662px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span15.noMargin {
  float: left;
  width: 710px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span16.noMargin {
  float: left;
  width: 758px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span17.noMargin {
  float: left;
  width: 806px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span18.noMargin {
  float: left;
  width: 854px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span19.noMargin {
  float: left;
  width: 902px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span20.noMargin {
  float: left;
  width: 950px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span21.noMargin {
  float: left;
  width: 998px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span22.noMargin {
  float: left;
  width: 1046px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span23.noMargin {
  float: left;
  width: 1094px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
.span24.noMargin {
  float: left;
  width: 1142px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 0;
  margin-right: 0;
}
a,
.fakeLink {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  color: #36322f;
  text-decoration: none;
}
a:hover,
.fakeLink:hover {
  text-decoration: none;
  color: #ff9600;
}
b {
  font-weight: bold;
}
.noMargin {
  margin: 0 !important;
}
.noPadding {
  padding: 0 !important;
}
.main {
  color: #36322f;
}
.secondary {
  color: #8AAED1;
}
.italic {
  font-style: italic;
}
.boldColor {
  color: black;
}
.white {
  color: white;
}
.tinyMargin {
  margin: 5px 0;
}
.smallMargin {
  margin: 10px 0;
}
.midMargin {
  margin: 20px 0;
}
.bigMargin {
  margin: 30px 0;
}
.hugeMargin {
  margin: 60px 0;
}
.giantPadding {
  padding: 45px 60px !important;
}
.hugePadding {
  padding: 30px 40px !important;
}
.bigPadding {
  padding: 20px 30px !important;
}
.midPadding {
  padding: 10px 15px !important;
}
.smallPadding {
  padding: 7px 11px !important;
}
.singleLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}
ul.tiret li {
  list-style: none inside;
  margin: 10px 0px;
}
ul.tiret li:before {
  content: "- ";
}
.red,
.error {
  color: #dd4b39;
}
.uppercase {
  text-transform: uppercase;
}
.parallax {
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bigRounded {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.hugeRounded {
  -webkit-border-radius: 140px;
  -moz-border-radius: 140px;
  border-radius: 140px;
}
.smallShadowed {
  -webkit-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
}
.shadowed {
  -webkit-box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
}
.textShadowed {
  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.85);
}
.relative {
  position: relative;
}
.leftArrowOnBlock {
  position: absolute;
  left: -15px;
  top: 15px;
  background: url('../img/home/homeRightArrow.png');
  width: 44px;
  height: 89px;
}
.percent-50 {
  width: 50%;
  float: left;
}
img {
  vertical-align: middle;
}
.leftAlign {
  text-align: left;
}
.center {
  text-align: center;
}
.justify {
  text-align: justify;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.hoverScale {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.hoverScale:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.inbl {
  display: inline-block;
}
.table-100 {
  width: 100%;
}
.smallPaddingTable th,
.smallPaddingTable td {
  padding: 5px;
  vertical-align: top;
}
.midPaddingTable th,
.midPaddingTable td {
  padding: 12px;
  vertical-align: top;
}
.bigPaddingTable th,
.bigPaddingTable td {
  padding: 20px;
  vertical-align: top;
}
.tableCentered th,
.tableCentered td {
  text-align: center;
  vertical-align: middle;
}
.progressBar {
  background: #535353;
  height: 12px;
  position: relative;
}
.progressBar .progress {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: 12px;
  background: #fff;
}
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
#popups {
  opacity: 0;
  display: none;
  position: absolute;
  left: 37%;
  left: calc(50% - 493px / 2);
  left: -moz-calc(50% - 493px / 2);
  left: -webkit-calc(50% - 493px / 2);
  top: 0;
  z-index: 100001;
}
#popups .popup {
  display: none;
  width: 493px;
  margin: 20px auto;
  background: white;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  padding: 20px;
}
#popups .popup .closePopup {
  position: absolute;
  top: 30px;
  right: 10px;
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -502px;
  height: 14px;
  width: 15px;
  cursor: pointer;
}
#popups-overlay {
  opacity: 0;
  display: none;
  width: 100%;
  position: absolute;
  height: calc(100% + 307px);
  height: -moz-calc(100% + 307px);
  height: -webkit-calc(100% + 307px);
  z-index: 100000;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
}
.pl_datetimepicker {
  z-index: 100;
}
.pagination li {
  display: inline-block;
  margin: 2px;
}
.pagination li a {
  color: #36322f;
}
.pagination li.active a {
  color: creay #fff;
}
.pointer {
  cursor: pointer;
}
body {
  background: #fcebb6;
  color: #5e412f;
}
#searchForm input:not([type="submit"]),
#searchForm textarea,
#searchForm .select,
#searchForm select {
  padding: 10px 15px;
  height: auto;
  font-size: 32px;
  line-height: 40px;
  color: white;
  background: transparent;
  border: none;
}
@media screen and (max-width: 768px) {
  #searchForm input:not([type="submit"]),
  #searchForm textarea,
  #searchForm .select,
  #searchForm select {
    font-size: 19px;
    line-height: 25px;
  }
}
#searchForm button {
  border: none;
  background: transparent;
  opacity: 0.9;
  padding: 0;
}
#searchForm button:hover {
  border: none;
  background: transparent;
  opacity: 1;
}
#searchForm input:not([type="submit"])::-webkit-input-placeholder,
#searchForm textarea::-webkit-input-placeholder,
#searchForm .select {
  color: white;
}
#searchForm input:not([type="submit"]):hover,
#searchForm textarea:hover,
#searchForm .select:hover,
#searchForm select:hover {
  border: none;
  background: transparent;
}
#searchForm input:not([type="submit"]):focus,
#searchForm textarea:focus,
#searchForm .select:focus,
#searchForm select:focus {
  border: none;
  background: transparent;
}
.skymap {
  background: url("../img/skymap.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center 50%;
  background-size: cover;
  width: 100%;
}
.projetThumbContainer {
  padding: 7px 11px !important;
  width: 33%;
  float: left;
  margin-bottom: 20px;
}
@media screen and (max-width: 1200px) {
  .projetThumbContainer {
    float: none;
    width: auto;
    max-width: 300px;
    margin: 10px auto;
  }
}
.projetThumbContainer .projetThumb {
  -webkit-border-top-right-radius: 100px;
  -webkit-border-bottom-left-radius: 100px;
  -moz-border-radius-topright: 100px;
  -moz-border-radius-bottomleft: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  /* Changed here */
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  width: 351px;
  height: 474px;
}
.projetThumbContainer .projetThumb .projetThumb-image {
  height: 186px;
  max-width: 100%;
  overflow: hidden;
}
.projetThumbContainer .projetThumb .projetThumb-nom {
  background: #fff;
  color: #fff;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.25em;
  font-weight: 700;
  padding: 10px;
}
.projetThumbContainer .projetThumb .projetThumb-tab {
  margin-bottom: 10px;
  font-weight: bold;
}
.projetThumbContainer .projetThumb .projetThumb-numbers {
  margin: 20px 10px 10px;
}
.projetThumbContainer .projetThumb .projetThumb-numbers .part1,
.projetThumbContainer .projetThumb .projetThumb-numbers .part2,
.projetThumbContainer .projetThumb .projetThumb-numbers .part3 {
  float: left;
  min-width: 33%;
  font-weight: bold;
}
.projetThumbContainer .projetThumb .projetThumb-numbers .part1 {
  font-size: 1.328125em;
}
.projetThumbContainer .projetThumb .projetThumb-numbers .part1 img {
  vertical-align: middle;
}
.projetThumbContainer .projetThumb .projetThumb-numbers .part2 {
  font-family: "latoLight";
  font-size: 0.9375em;
}
.projetThumbContainer .projetThumb .projetThumb-numbers .part3 {
  font-family: "latoLight";
  font-size: 0.9375em;
}
.projetThumbContainer .projetThumb p.description {
  height: 61px;
  line-height: 21px;
  overflow: hidden;
}
.projetThumbContainer .projetThumb:hover {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
.curlyBack {
  background: url('../bundles/jamain/img/curly.gif');
  z-index: 10;
}
.roundTop {
  height: 80px;
  padding: 0;
  position: relative;
  background-repeat: repeat;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  overflow: hidden;
  border-top-left-radius: 50% 100%;
  border-top-right-radius: 50% 100%;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  background-position: 0 -70px;
}
.roundBottom {
  height: 80px;
  padding: 0;
  background-repeat: repeat;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  overflow: hidden;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 50% 100%;
  border-bottom-left-radius: 50% 100%;
  background-position: 0 -77px;
  bottom: 10px;
}
ol.rounded-list {
  counter-reset: li;
  font-size: 1.953125em;
}
ol.rounded-list li {
  position: relative;
  margin: 40px 0;
}
ol.rounded-list li:before {
  counter-increment: li;
  content: counter(li);
  position: absolute;
  left: -80px;
  top: 52%;
  margin-top: -1.3em;
  height: 60px;
  width: 60px;
  line-height: 2em;
  border: .3em solid #f07818;
  text-align: center;
  font-weight: bold;
  border-radius: 2em;
  transition: all 0.3s ease-out;
}
ol.rounded-list li:hover:before {
  -ms-transform: rotate(360deg);
  /* IE 9 */
  -webkit-transform: rotate(360deg);
  /* Chrome, Safari, Opera */
  transform: rotate(360deg);
}
.curled {
  background: url("../bundles/jamain/img/topBottomCurl.png") no-repeat;
  width: 135px;
  height: 173px;
  display: inline-block;
  color: #fff;
  color: white;
  padding-top: 50px;
  margin: 0 18px;
}
.skyTitle {
  width: 100%;
  background-image: url("../bundles/jamain/img/sky_bg.png");
  background-position: 0px 0px;
  background-repeat: repeat-x;
  -webkit-animation: clouds 60s linear infinite;
  -moz-animation: clouds 60s linear infinite;
  -ms-animation: clouds 60s linear infinite;
  -o-animation: clouds 60s linear infinite;
  animation: clouds 60s linear infinite;
  height: 200px;
  text-align: center;
  color: #fff;
  color: white;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  padding-top: 120px;
}
.skyTitle h1 {
  font-size: 2.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "oswald";
  text-shadow: 0 5px 7px #7b9cbb;
}
@media screen and (max-width: 992px) {
  .skyTitle {
    height: 200px;
    padding-top: 140px;
  }
}
@media screen and (max-width: 768px) {
  .skyTitle {
    height: 90px;
    padding-top: 20px;
  }
  .skyTitle h1 {
    font-size: calc(20px);
  }
}
.contactForm input:not([type="submit"]),
.contactForm textarea,
.contactForm .select,
.contactForm select {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 2px solid #5e412f !important;
  background: transparent;
  color: #5e412f;
  padding: 20px 7px;
  font-size: 22px;
  line-height: 36px;
  margin: 20px 0;
}
.contactForm textarea,
.contactForm textarea:hover {
  height: 250px;
  border-bottom: 2px solid #5e412f !important;
}
.contactForm input:not([type="submit"])::-webkit-input-placeholder,
.contactForm textarea::-webkit-input-placeholder,
.contactForm .select {
  color: rgba(94, 65, 47, 0.5);
}
.contactForm input:not([type="submit"]):hover,
.contactForm textarea:hover,
.contactForm .select:hover,
.contactForm select:hover {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 2px solid #5e412f !important;
  background: transparent;
  color: #5e412f;
}
.contactForm input:not([type="submit"]):focus,
.contactForm textarea:focus,
.contactForm .select:focus,
.contactForm select:focus {
  border-top: 0 none;
  border-left: 0 none;
  border-right: 0 none;
  border-bottom: 2px solid #5e412f;
  background: transparent;
  color: #5e412f;
}
.social {
  font-family: "Social-Icons";
}
.social > a {
  color: red;
  width: 35px;
  height: 35px;
  line-height: 40px;
  font-size: 25px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
}
.social .fb:before {
  color: #3b5998;
  content: "\e61c";
}
.social .twitter:before {
  color: #00aced;
  content: "\e618";
}
.social .google:before {
  color: #dd4b39;
  content: "\e605";
}
.social .linkedIn:before {
  color: #007BB6;
  content: "\e632";
}
.social .pinterest:before {
  color: #d53939;
  content: "\e647";
}
.social a:before {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.social a:hover:before {
  color: #565656;
}
.slideBlock {
  background: #5e412f;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  display: block;
  padding: 10px 20px 6px;
  margin: 15px 0 15px 15px;
  color: #fcebb6;
}
.slideBlock .title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "oswald";
  font-size: 19px;
}
a.slideBlock:hover {
  color: #8AAED1;
}
.errorTooltip {
  background: #8E0000;
  color: #fff;
  color: white;
  padding: 7px 11px !important;
  position: absolute;
  bottom: 102%;
  z-index: 20;
  display: none;
  width: 300px;
}
.jaTable tr {
  border-top: 1px solid rgba(94, 65, 47, 0.2);
}
.jaTable th,
.jaTable td {
  vertical-align: middle;
}
.topMenu {
  background: white;
  text-transform: uppercase;
  color: #8AAED1;
  text-align: center;
  margin-bottom: 20px;
}
.topMenu li {
  margin: 0;
  display: inline-block;
  font-weight: bold;
}
.topMenu li > a,
.topMenu li > span {
  display: block;
  padding: 20px 15px;
  color: #8aaed1;
}
.topMenu li > a:hover {
  color: #ff9600;
}
.topMenu li.current > a,
.topMenu li.craue_formflow_current_step > a,
.topMenu li.current > span,
.topMenu li.craue_formflow_current_step > span {
  background: #f07818;
  color: #fff;
  padding: 20px 15px 23px;
}
.topMenu li.craue_formflow_skipped_step {
  text-decoration: line-through;
}
.bigLink {
  position: relative;
  height: 300px;
  overflow: hidden;
  background: #fff;
  color: #8AAED1;
  display: block;
  text-align: center;
}
.bigLink img {
  height: 100px;
}
.bigLink .link {
  position: absolute;
  height: 300px;
  width: 100%;
  z-index: 10;
  background: #fff;
  color: #fff;
  color: white;
  text-align: center;
  font-size: 1.953125em;
  letter-spacing: 1px;
  font-family: "oswald";
  text-transform: uppercase;
  line-height: 300px;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
.bigLink:hover .link {
  opacity: 0.9;
}
.projetCategorie {
  display: inline-block;
  color: #5e412f;
  font-weight: bold;
  width: 12.1%;
  text-align: center;
  margin: 15px 0;
}
@media screen and (max-width: 992px) {
  .projetCategorie {
    width: 24.2%;
  }
}
@media screen and (max-width: 768px) {
  .projetCategorie {
    width: 49%;
  }
}
* {
  margin: 0;
}
html,
body {
  height: 100%;
}
#wrapper {
  position: relative;
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -280px;
}
#footer,
#push {
  height: 280px;
}
#push {
  background: #fff;
}
.icon {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
}
.icon-downArrow {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px 0px;
  height: 20px;
  width: 20px;
}
.icon-whiteTopArrow {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -39px;
  height: 44px;
  width: 44px;
}
.icon-plus {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -103px;
  height: 33px;
  width: 33px;
}
.icon-file {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -174px;
  height: 35px;
  width: 35px;
}
.icon-question {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -234px;
  height: 32px;
  width: 33px;
}
.icon-creditCard {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -320px;
  height: 20px;
  width: 29px;
}
.icon-creditCardAct {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -280px;
  height: 20px;
  width: 29px;
}
.icon-cheque {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -404px;
  height: 21px;
  width: 28px;
}
.icon-chequeAct {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -364px;
  height: 21px;
  width: 28px;
}
.icon-profil {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: -1px -438px;
  height: 23px;
  width: 23px;
}
.icon-parametres {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -474px;
  height: 11px;
  width: 12px;
}
.icon-close {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -502px;
  height: 14px;
  width: 15px;
  cursor: pointer;
}
.icon-search {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -502px;
  height: 16px;
  width: 16px;
}
.icon-createFolder {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -706px;
  height: 24px;
  width: 24px;
}
.icon-deposerFichier {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -536px;
  height: 26px;
  width: 26px;
}
.icon-whiteEmail {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -767px;
  height: 21px;
  width: 21px;
}
.icon-whitePrint {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -819px;
  height: 21px;
  width: 21px;
}
.icon-whiteDownload {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -866px;
  height: 21px;
  width: 21px;
}
.icon-whiteDelete {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -908px;
  height: 21px;
  width: 21px;
}
.icon-edit {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -940px;
  height: 18px;
  width: 18px;
}
.icon-addUser {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -968px;
  height: 24px;
  width: 26px;
}
.icon-otherProfile {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -1014px;
  height: 22px;
  width: 27px;
}
.icon-beneficiaire {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -1006px;
  height: 30px;
  width: 33px;
}
.icon-membre {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -1116px;
  height: 30px;
  width: 33px;
}
.icon-paiement {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -395px;
  height: 30px;
  width: 33px;
}
.icon-beneficiaire.act {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -1056px;
  height: 30px;
  width: 33px;
}
.icon-membre.act {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -1169px;
  height: 30px;
  width: 33px;
}
.icon-paiement.act {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -356px;
  height: 30px;
  width: 33px;
}
.icon-parametresGrey {
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -1220px;
  height: 11px;
  width: 12px;
}
.back.btn {
  border-bottom: none;
}
.btn-rounded.btn {
  border: none;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
}
.btn.btn-grey,
input[type=submit].btn-grey,
button.btn-grey {
  background: #535353;
  border-bottom: none;
}
.btn.btn-grey:hover,
input[type=submit].btn-grey:hover,
button.btn-grey:hover {
  background: #36322f;
}
div.btn.btn-grey:hover {
  background: #535353;
}
.btn.btn-passed,
input[type=submit].btn-passed,
button.btn-passed {
  background: #FCC196;
  text-transform: none;
  color: #fff;
  text-shadow: none;
}
.btn.btn-passed:hover,
input[type=submit].btn-passed:hover,
button.btn-passed:hover {
  background: #fba464;
  color: #e6e6e6;
}
.btn.btn-white,
input[type=submit].btn-white,
button.btn-white {
  background: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  color: #535353;
}
.btn.btn-white:hover,
input[type=submit].btn-white:hover,
button.btn-white:hover {
  color: #36322f;
}
.btn.btn-inversed,
input[type=submit].btn-inversed,
button.btn-inversed {
  background: #fff;
  color: #36322f;
}
.btn.btn-inversed:hover,
input[type=submit].btn-inversed:hover,
button.btn-inversed:hover {
  color: #535353;
}
.btn-back:before {
  content: "< ";
  color: #fff;
}
.btn.btn-mid {
  padding: 8px 12px;
  font-size: 0.859375em;
}
.btn.btn-small {
  padding: 4px 8px;
  font-size: 0.859375em;
}
.btn-block {
  display: block !important;
  width: 100%;
}
.btn-tiny {
  padding: 4px 8px;
  font-size: 0.859375em;
}
.btn.block {
  display: block;
}
.btn.btn-secondary {
  background-color: #fff;
}
.btn.btn-secondary:hover {
  background-color: #afa097;
}
.btn.btn-secondaryLight {
  background-color: #FCC196;
}
.btn.btn-secondaryLight:hover {
  background-color: #fba464;
}
.btn-arrow {
  background: #8AAED1 url("../img/icons/rightArrow.png") no-repeat 95% 15px;
  padding-right: 40px;
}
.btn-arrow:hover {
  background-position: 97% 15px;
}
.btn:disabled {
  background: #aaa;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  cursor: auto;
}
.btn-onSecondary {
  background: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 8px 30px;
  color: #fff !important;
  border: 2px solid #fff;
  text-align: center;
  outline: none;
  text-decoration: none;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1px;
  font-family: "oswald";
  text-transform: uppercase;
  font-size: 1.015625em;
  padding: 4px 15px;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}
.btn-onSecondary:hover {
  background: rgba(255, 255, 255, 0.2);
}
.btn-slide {
  padding: 8px 30px;
  color: #fff !important;
  border: 1px solid #fff;
  text-align: center;
  outline: none;
  text-decoration: none;
  font-size: 20px;
  line-height: 30px;
  font-family: "oswald";
  text-transform: uppercase;
  letter-spacing: 1px;
  background: rgba(255, 255, 255, 0);
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
}
.btn-slide:hover {
  background: rgba(255, 255, 255, 0.1);
}
.btn-slide.btn-slide-mainVeryDark {
  padding: 8px 30px;
  color: #fff !important;
  border: 1px solid #fff;
  text-align: center;
  outline: none;
  text-decoration: none;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1px;
  font-family: "oswald";
  text-transform: uppercase;
  border-color: #5e412f;
  border-width: 2px;
  color: #5e412f !important;
}
.btn-slide.btn-slide-mainVeryDark:hover {
  color: #fcebb6 !important;
  background: #5e412f;
}
.tp-static-layers .btn-slide {
  padding: 8px 30px !important;
  border: 1px solid #fff !important;
  font-size: 20px !important;
  line-height: 30px !important;
}
.btn-bigMarron {
  background: #5e412f;
  color: #fcebb6;
  letter-spacing: 1px;
  font-family: "oswald";
  text-transform: uppercase;
  font-size: 35px;
  text-align: center;
  padding: 20px;
}
.btn-bigMarron:hover,
.btn-bigMarron.act {
  background: #4d3527;
  color: #fcebb6;
}
.btn-bigMarron.btn-slide {
  border: none;
  color: #fcebb6 !important;
}
/* forms */
label {
  line-height: 22px;
}
input:not([type="submit"]),
textarea,
.select,
select {
  font-family: "lato";
  display: inline-block;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px;
  height: 30px;
  font-size: 1.015625em;
  color: #535353;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 0px 0 transparent;
  -moz-box-shadow: 0px 0px 0 transparent;
  box-shadow: 0px 0px 0 transparent;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 1px solid #eee;
}
input.uncorrect:not([type="submit"]),
textarea.uncorrect,
select.uncorrect {
  border: 1px solid #CC2C2C;
}
input.correct:not([type="submit"]),
textarea.correct,
select.correct {
  border: 1px solid #8FDC95;
}
input:not([type="submit"])::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
.select {
  color: #aaa;
}
input:not([type="submit"]):hover,
textarea:hover,
select:hover {
  border: 1px solid rgba(138, 174, 209, 0.5);
  background: rgba(255, 255, 255, 0.9);
}
input:not([type="submit"]):focus,
textarea:focus,
select:focus {
  -webkit-box-shadow: 0px 0px 0 transparent;
  -moz-box-shadow: 0px 0px 0 transparent;
  box-shadow: 0px 0px 0 transparent;
  border: 1px solid #8AAED1;
  outline: none;
  background: #ffffff;
}
input[type="checkbox"] {
  vertical-align: middle;
}
input[readonly="readonly"]:not([type="submit"]) {
  background-color: #e1e1e1;
}
textarea {
  height: 90px;
  resize: none;
}
.form-100 input:not([type="submit"]):not([type="checkbox"]),
.form-100 textarea,
.form-100 .select,
.form-100 select {
  width: 100%;
}
.doubleForm {
  clear: both;
}
.doubleForm fieldset:first-child {
  padding-right: 20px;
}
.doubleForm fieldset:last-child {
  padding-left: 20px;
}
.doubleForm fieldset {
  width: 50%;
  float: left;
}
.listForm ul li > div {
  margin: 20px 0 10px;
}
.listForm ul li > div label {
  display: inline-block;
}
.listForm ul li > div .error {
  display: inline;
}
.tableForm table {
  width: 100%;
}
.tableForm table th,
.tableForm table td {
  padding: 5px;
  vertical-align: top;
}
.tableForm table td,
.tableForm table th {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.tableForm table td input:not([type="submit"]):not([type="checkbox"]),
.tableForm table th input:not([type="submit"]):not([type="checkbox"]),
.tableForm table td textarea,
.tableForm table th textarea,
.tableForm table td .select,
.tableForm table th .select,
.tableForm table td select,
.tableForm table th select,
.tableForm table td .reSelectContainer,
.tableForm table th .reSelectContainer {
  position: relative;
  bottom: 3px;
  float: left;
  min-width: 150px;
}
.tableForm table td label,
.tableForm table th label {
  float: left;
  margin-right: 10px;
}
.tableForm table td button:not(.noBlock),
.tableForm table th button:not(.noBlock),
.tableForm table td input[type=submit]:not(.noBlock),
.tableForm table th input[type=submit]:not(.noBlock) {
  display: block;
  width: 100%;
  padding: 6px;
  margin: 6px 0;
}
.searchInput {
  background: #36322f;
  padding-right: 30px;
  display: inline-block;
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: 126px -666px;
}
.searchInput input[type=search] {
  background: white;
}
.searchInput:after {
  background: #36322f;
  width: 20px;
  height: 20px;
  background-image: url("../img/icons.png");
  background-repeat: no-repeat;
  background-position: top left;
  display: inline-block;
  vertical-align: middle;
  background-position: 0px -502px;
  height: 16px;
  width: 16px;
}
.genemuDatePicker select,
.datePicker select {
  display: inline-block;
  width: 70px;
  min-width: 0 !important;
}
.genemuDatePicker button,
.datePicker button {
  padding: 3px 8px;
  margin-left: 3px;
}
.starRequired label.required:after {
  content: "*";
  font-size: 1.328125em;
}
.moneyInput-container .currency {
  font-size: 1.953125em;
  font-weight: bold;
  position: relative;
  right: 33px;
  top: 3px;
}
.moneyInput-container .moneyInput {
  width: 100px;
  padding: 8px 10px;
  padding-right: 25px;
  height: auto;
  font-weight: bold;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #5e412f;
}
#header {
  position: absolute;
  top: 0;
  z-index: 600;
  width: 100%;
  margin: auto;
  color: #fff;
  color: white;
  letter-spacing: 1px;
  font-family: "oswald";
  text-transform: uppercase;
  font-size: 1.328125em;
  padding: 0 20px;
}
#header ul.locale_chooser {
  margin: 0;
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}
#header ul.locale_chooser li a {
  padding: 0px 10px;
  text-transform: uppercase;
  background: #8aaed1;
}
@media screen and (max-width: 768px) {
  #header {
    position: static;
    background: #ff9600;
    -webkit-box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
    padding: 0;
    padding-bottom: 15px;
  }
}
#header nav#header-topMenu {
  margin-top: 50px;
  float: right;
}
#header nav#header-topMenu ul {
  padding: 0;
}
#header nav#header-topMenu ul li {
  float: left;
  display: inline-block;
}
#header nav#header-topMenu ul li a {
  padding: 18px 10px;
  color: #fff;
  color: white;
  text-shadow: 0 0 10px #000;
  opacity: 0.9;
}
#header nav#header-topMenu ul li a:hover {
  color: #fff;
  color: white;
  opacity: 1;
}
@media screen and (max-width: 1200px) {
  #header nav#header-topMenu {
    float: none;
    max-width: 600px;
    margin: 5px auto 0;
  }
  #header nav#header-topMenu ul {
    text-align: center;
  }
  #header nav#header-topMenu ul li {
    float: none;
    margin: 5px;
    display: inline-block;
  }
}
@media screen and (max-width: 992px) {
  #header nav#header-topMenu {
    float: none;
    max-width: 600px;
    margin: 5px auto 0;
  }
  #header nav#header-topMenu ul {
    text-align: center;
  }
  #header nav#header-topMenu ul li {
    float: none;
    margin: 5px;
    display: inline-block;
  }
}
@media screen and (max-width: 768px) {
  #header nav#header-topMenu {
    display: none;
    text-align: center;
  }
  #header nav#header-topMenu ul li {
    display: inline-block;
    float: none;
  }
  #header nav#header-topMenu ul li a {
    font-size: 1.015625em;
  }
}
@media screen and (max-width: 768px) {
  #header nav#header-topMenu.opened {
    display: block;
  }
}
#header #logo {
  margin-top: 5px;
  float: left;
}
@media screen and (max-width: 992px) {
  #header #logo {
    float: none;
    margin: 0 auto;
    width: 150px;
  }
  #header #logo img {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  #header #logo {
    width: 220px;
  }
  #header #logo img {
    width: 120px;
  }
}
#header #handle {
  display: none;
}
@media screen and (max-width: 768px) {
  #header #handle {
    display: inline-block;
    cursor: pointer;
    position: relative;
    top: 11px;
    left: 20px;
  }
}
#header #headerHoverMenu {
  width: 94%;
  margin-top: 170px;
  margin: 40px auto 0;
  background: #fff;
  -webkit-border-top-right-radius: 100px;
  -webkit-border-bottom-left-radius: 100px;
  -moz-border-radius-topright: 100px;
  -moz-border-radius-bottomleft: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  overflow: hidden;
  position: relative;
  -webkit-box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
#header #headerHoverMenu .categorie {
  padding: 15px 0px;
}
#header #headerHoverMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 992px) {
  #header #headerHoverMenu {
    -webkit-border-top-right-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-topright: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }
}
#header #headerHoverMenu .left {
  color: #fff;
  color: white;
  width: 20%;
  min-width: 171px;
  background: #8AAED1;
  height: 100%;
  position: absolute;
  -webkit-border-bottom-left-radius: 100px;
  -moz-border-radius-bottomleft: 100px;
  border-bottom-left-radius: 100px;
}
@media screen and (max-width: 992px) {
  #header #headerHoverMenu .left {
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-bottom-left-radius: 30px;
  }
}
#header #headerHoverMenu .left nav ul li {
  position: relative;
}
#header #headerHoverMenu .left nav ul li a {
  display: block;
  padding: 20px 15px;
  color: #fff;
  color: white;
  text-transform: uppercase;
  font-size: 1.328125em;
  letter-spacing: 1px;
  font-family: "oswald";
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
@media screen and (max-width: 992px) {
  #header #headerHoverMenu .left nav ul li a {
    font-size: 1.015625em;
  }
}
#header #headerHoverMenu .left nav ul li a:after {
  position: absolute;
  right: 15px;
  top: 26px;
  margin-top: -6px;
  content: "\f105";
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  font-size: 1.328125em;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: right 0.3s ease-out;
  -moz-transition: right 0.3s ease-out;
  -o-transition: right 0.3s ease-out;
  transition: right 0.3s ease-out;
}
#header #headerHoverMenu .left nav ul li a:hover {
  background: #7ba4cb;
}
#header #headerHoverMenu .left nav ul li a:hover:after {
  right: 5px;
}
#header #headerHoverMenu .right {
  margin-left: 16%;
  background: #fff;
  height: 100%;
  padding: 1% 50px;
}
#header #headerHoverMenu .right a.element {
  margin: 8px 1%;
  display: inline-block;
  color: #8AAED1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "oswald";
  width: 22.2%;
  opacity: 0.9;
}
@media screen and (max-width: 992px) {
  #header #headerHoverMenu .right a.element {
    font-size: 0.9375em;
  }
}
#header #headerHoverMenu .right a.element img {
  width: 100%;
  margin-bottom: 4px;
  display: block;
}
#header #headerHoverMenu .right a.element:hover {
  opacity: 1;
}
#content {
  min-height: 500px;
}
#footer {
  background: #5e412f;
  color: #fcebb6;
  padding-top: 30px;
}
#footer ul.locale_chooser {
  margin: 0;
  padding: 0;
}
#footer ul.locale_chooser li a {
  padding: 5px 10px;
  text-transform: uppercase;
  background: #8aaed1;
}
#footer ul.locale_chooser li a:hover {
  color: #4f2f1b;
  padding: 5px 11px;
}
@media screen and (max-width: 768px) {
  #footer {
    padding-top: 0px;
    height: auto;
  }
}
#footer h4 {
  letter-spacing: 1px;
  font-family: "oswald";
  text-transform: uppercase;
  font-size: 1.953125em;
  letter-spacing: 3px;
  margin-bottom: 15px;
}
@media screen and (max-width: 992px) {
  #footer h4 {
    font-size: 1.328125em;
  }
}
#footer nav {
  width: 25%;
  float: left;
}
@media screen and (max-width: 768px) {
  #footer nav {
    float: none;
    max-width: 200px;
    width: auto;
    margin: 0 auto 30px;
    text-align: center;
  }
}
#footer nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#footer nav ul li {
  margin: 0;
}
#footer nav ul li a {
  font-weight: bold;
  display: block;
  margin: 3px 0;
  font-size: 1.015625em;
  color: #fcebb6;
  font-size: 15px;
}
#footer nav ul li a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  #Social-Sidebar {
    display: none;
  }
}
#Social-Sidebar a {
  font-weight: bold;
}
#flashContainer {
  position: fixed;
  right: 0;
  top: 53px;
  max-width: 300px;
  z-index: 100;
}
#flashContainer .notification {
  -webkit-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
  position: relative;
}
#flashContainer .notification-red {
  background: #457AAF;
  color: #fff;
  color: white;
}
#flashContainer .notification-green {
  background: #457AAF;
  color: #fff;
  color: white;
}
#flashContainer .close {
  font-weight: bold;
  color: #fff;
  color: white;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
#page-landing {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: blue;
  z-index: 1;
}
#page-landing .overlay {
  background: rgba(12, 121, 211, 0.45);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}
#page-landing > .centeredContainer {
  position: relative;
  z-index: 3;
}
#page-home {
  /** home - categories  **/
}
#page-home video#bgvid {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background: url('../bundles/jamain/img/video/fallback.jpg') no-repeat;
  background-size: cover;
  transition: 1s opacity;
  object-fit: cover;
}
#page-home #masterslider ul {
  margin: 0;
  padding: 0;
}
#page-home searchForm,
#page-home #searchForm input {
  font-size: 18px;
}
#page-home #projets-une {
  background-color: #fff;
  padding-left: 25px;
  padding-right: 25px;
}
#page-home #projets-une h1 {
  margin-top: 10px;
  margin-bottom: 10px;
}
#page-home #categories {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #eef6fd;
}
#page-home div.categorie {
  padding: 12px 30px;
  border-radius: 15px;
}
#page-home div.categorie div.icon {
  margin-bottom: 5px;
  clear: both;
}
#page-home div.categorie,
#page-home a.categorie {
  margin: 5px;
  color: #535353;
}
#page-home div.categorie:hover,
#page-home div.categorie:hover a {
  background-color: #8aaed1;
  cursor: pointer;
  cursor: hand;
  color: #fff;
  text-decoration: none;
}
#page-home #home-map a:hover {
  color: #4f2f1b;
}
#page-home #mapbox {
  background: linear-gradient(bottom, #fff 49%, #eef6fd 49%, #eef6fd 100%);
}
#page-home #roundmap,
#page-home #map_canvas {
  border-top-left-radius: 100% 25%;
  border-top-right-radius: 100% 25%;
  border-bottom-right-radius: 100% 25%;
  border-bottom-left-radius: 100% 25%;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  /* this fixes the overflow:hidden in Chrome/Opera  */
}
@media screen and (max-width: 768px) {
  #page-home #roundmap,
  #page-home #map_canvas {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-mask-image: 0;
  }
}
#page-home #home-choose {
  width: 100%;
  padding: 0;
}
#page-home #home-choose a.left,
#page-home #home-choose a.right {
  background: #f07818;
  height: 350px;
  line-height: 350px;
  width: 50%;
  float: left;
  position: relative;
}
@media screen and (max-width: 992px) {
  #page-home #home-choose a.left,
  #page-home #home-choose a.right {
    height: 250px;
    line-height: 250px;
  }
}
@media screen and (max-width: 768px) {
  #page-home #home-choose a.left,
  #page-home #home-choose a.right {
    height: 200px;
    line-height: 200px;
  }
}
#page-home #home-choose a.left h3,
#page-home #home-choose a.right h3 {
  font-size: 50px;
  line-height: 70px;
  width: 97%;
  top: 35px;
  margin: auto;
  line-height: normal;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  z-index: 10;
  position: relative;
  color: #fff;
  color: white;
}
@media screen and (max-width: 992px) {
  #page-home #home-choose a.left h3,
  #page-home #home-choose a.right h3 {
    font-size: 30px;
  }
}
@media screen and (max-width: 768px) {
  #page-home #home-choose a.left h3,
  #page-home #home-choose a.right h3 {
    font-size: 20px;
  }
}
#page-home #home-choose a.left img,
#page-home #home-choose a.right img {
  position: absolute;
  left: 0px;
  top: 0;
  height: 0px;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.5s ease;
  -moz-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}
#page-home #skymap {
  height: auto;
  background-image: url("../../img/skymap.jpg");
  background-color: #74b8fc;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
#page-home .flipLink {
  width: 12%;
  float: left;
}
@media screen and (max-width: 768px) {
  #page-home .flipLink {
    width: 40%;
    margin: 20px 5%;
  }
}
#page-home .slideshow {
  margin: 20px 0;
  height: 1020px;
}
@media screen and (max-width: 1200px) {
  #page-home .slideshow {
    height: 3050px;
  }
}
.tp-video-play-button {
  display: none !important;
}
#slider-caption {
  position: absolute;
  top: 300px;
  z-index: 100;
  width: 100%;
}
#slider-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 80;
}
#page-commentCaMarche #parallax-1 {
  background-image: url("../../bundles/jamain/img/commentCaMarche/parallax1.jpg");
  height: 600px;
}
#page-commentCaMarche #parallax-2 {
  background-image: url("../../bundles/jamain/img/commentCaMarche/parallax2.jpg");
  height: 600px;
}
#page-laPetiteHistoire {
  background: url("../../bundles/jamain/img/laPetiteHistoire/bg.gif") repeat scroll 0 0 #8aaed1;
  -webkit-animation: clouds 60s linear infinite;
  -moz-animation: clouds 60s linear infinite;
  -ms-animation: clouds 60s linear infinite;
  -o-animation: clouds 60s linear infinite;
  animation: clouds 60s linear infinite;
  min-height: 500px;
}
#page-laPetiteHistoire .timeline {
  position: relative;
  overflow: auto;
}
#page-laPetiteHistoire .timeline:before {
  background: none repeat scroll 0 0 #fff;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 7px;
}
#page-laPetiteHistoire .timeline h2 {
  background: none repeat scroll 0 0 #f07818;
  clear: both;
  color: white;
  font-size: 25px;
  margin: 0 auto 50px;
  max-width: 6em;
  padding: 10px;
  position: relative;
  text-align: center;
}
#page-laPetiteHistoire .timeline h3 {
  color: #f07818;
  font-size: 20px;
  font-family: 'oswald';
  margin-top: 0;
  padding: 11px;
  text-transform: uppercase;
}
#page-laPetiteHistoire .timeline p {
  padding: 15px 15px;
  font-style: italic;
}
#page-laPetiteHistoire .timeline ul {
  list-style: none;
  padding: 0 0 0 1em;
  z-index: 1;
}
#page-laPetiteHistoire .timeline li {
  background: none repeat scroll 0 0 #fff;
  margin: 0 0 18px;
  padding: 1em;
  position: relative;
}
#page-laPetiteHistoire .timeline li:before {
  content: '';
  width: 0;
  height: 0;
  border-top: 1em solid #fff;
  border-left: 1em solid transparent;
  position: absolute;
  left: -1em;
  top: 0;
}
#page-laPetiteHistoire .timeline time {
  color: #8aaed1;
  display: block;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
  width: 100%;
}
#page-laPetiteHistoire .timeline img {
  max-width: 370px;
  max-width: 90% !important;
  height: auto !important;
  margin: 5px auto;
}
@media screen and (min-width: 1160px) {
  #page-laPetiteHistoire {
    /*.container {
	max-width: 1000px;
	margin: 0 auto;
	}*/
  }
  #page-laPetiteHistoire .timeline:before {
    left: 49.6%;
  }
  #page-laPetiteHistoire .timeline ul {
    padding-left: 0;
    margin: 0 auto;
  }
  #page-laPetiteHistoire .timeline li {
    width: 43%;
  }
  #page-laPetiteHistoire .timeline li:nth-child(even) {
    float: right;
    margin-top: 5em;
  }
  #page-laPetiteHistoire .timeline li:nth-child(odd) {
    float: left;
  }
  #page-laPetiteHistoire .timeline li:nth-child(odd):before {
    border-top: 1em solid #fff;
    border-right: 1em solid transparent;
    right: -1em;
    left: auto;
  }
  #page-laPetiteHistoire .timeline li:nth-of-type(2n+1) {
    clear: both;
  }
}
#page-associationJamaa #associationJamaa-image {
  background-size: cover;
  height: 350px;
  background: url("../../img/associationJamaa/salesman.png") no-repeat scroll center center rgba(0, 0, 0, 0);
}
#page-rejoignezAventure #parallax-1 {
  background-image: url("../../bundles/jamain/img/rejoignezAventure/parallax1.jpg");
  height: 700px;
}
#page-rejoignezAventure #parallax-2 {
  background-image: url("../../bundles/jamain/img/rejoignezAventure/parallax2.jpg");
  height: 600px;
}
#page-rejoignezAventure #parallax-3 {
  background-image: url("../../bundles/jamain/img/rejoignezAventure/parallax3.jpg");
  height: 600px;
}
#page-rejoignezAventure #parallax-4 {
  background-image: url("../../bundles/jamain/img/rejoignezAventure/parallax4.jpg");
  height: 600px;
}
#page-rejoignezAventure #thumb {
  background: #5e412f;
  color: #fcebb6;
  font-size: 60px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  padding: 40px;
  display: inline-block;
}
#page-faq section {
  padding: 30px;
}
#page-faq section .centeredContainer {
  padding: 30px 40px !important;
}
#page-faq section h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 25px 0;
  position: relative;
  right: 27px;
}
#page-faq section h2 i {
  position: relative;
  right: 30px;
}
#page-faq section h3 {
  font-size: 1.328125em;
  font-weight: bold;
  margin: 15px 0;
  cursor: pointer;
}
#page-faq section .container {
  margin-bottom: 30px;
  font-size: 1.328125em;
  display: none;
}
#page-conditionsGenerales mark {
  background: yellow;
}
#page-conditionsGenerales mark.current {
  background: orange;
}
#page-conditionsGenerales .header {
  padding: 10px;
  width: 100%;
  background: #eef6fd;
  top: 0;
  left: 0;
}
#page-conditionsGenerales .header .searchbox {
  margin-left: auto;
  margin-right: 30px;
}
#page-conditionsGenerales section {
  padding: 30px;
}
#page-conditionsGenerales section .centeredContainer {
  padding: 30px 40px !important;
}
#page-conditionsGenerales section h2 {
  font-size: 30px;
  font-family: .bold;
  margin: 25px 0;
  position: relative;
  right: 27px;
}
#page-conditionsGenerales section h2 i {
  position: relative;
  right: 30px;
}
#page-conditionsGenerales section h3 {
  font-size: 1.328125em;
  font-weight: bold;
  margin: 15px 0;
  cursor: pointer;
}
#page-conditionsGenerales section .container {
  margin-bottom: 30px;
  font-size: 1.328125em;
  display: none;
}
#page-conditionsGenerales section .container-show {
  margin-bottom: 30px;
  font-size: 1.328125em;
}
#page-contact section {
  background: url("../img/contact/bg.jpg");
}
#page-contact section #contactForm {
  max-width: 500px;
  width: 50%;
  float: left;
}
#page-contact section #stamps {
  width: 50%;
  float: right;
}
#page-projets .projetThumb {
  margin-bottom: 30px;
}
#page-projets .projetThumb .item-body {
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
}
#page-projets .projetThumb .item-metabottom {
  border-left: 1px solid #535353;
  border-right: 1px solid #535353;
}
#page-projet {
  color: #535353;
  background: url("../../bundles/jamain/img/projet/white-blue.png");
}
#page-projet img {
  max-width: 720px;
}
@media screen and (max-width: 1200px) {
  #page-projet img {
    max-width: 90%;
  }
}
@media screen and (max-width: 992px) {
  #page-projet img {
    max-width: 90%;
  }
}
#page-projet .video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
#page-projet .video-container iframe,
#page-projet .video-container object,
#page-projet .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#page-projet ul.nav.nav-tabs {
  border-bottom: none;
}
#page-projet ul.nav.nav-tabs.nav-top-border li {
  border-top: 3px solid #aaa;
  line-height: 19px;
}
#page-projet ul.nav.nav-tabs.nav-top-border li a {
  border-radius: 0;
  margin: 0;
  border: 0;
  background-color: #fff;
  color: #535353;
}
#page-projet ul.nav.nav-tabs.nav-top-border li.active {
  border-top-color: #ff9600;
  line-height: 19px;
}
#page-projet ul.nav.nav-tabs.nav-top-border li:hover {
  border-top-color: #8aaed1;
}
#page-projet .tab-pane {
  background: #fff;
  padding: 20px;
}
#page-projet .tab-pane img {
  max-width: 720px;
}
@media screen and (max-width: 1200px) {
  #page-projet .tab-pane img {
    max-width: 640px;
  }
}
@media screen and (max-width: 992px) {
}
#page-projet .counterpart {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
#page-projet .counterpart .overbox {
  border-radius: 5px;
  background-color: #8aaed1;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 100;
  transition: all 300ms ease-out;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
}
#page-projet .counterpart:hover .overbox {
  opacity: 0.98;
}
#page-projet .counterpart .overtext {
  transition: all 300ms ease-out;
  transform: translateY(40px);
}
#page-projet .counterpart .title {
  font-size: 2.5em;
  text-transform: uppercase;
  opacity: 0;
  transition-delay: 0.1s;
  transition-duration: 0.2s;
}
#page-projet .counterpart:hover .title,
#page-projet .counterpart:focus .title {
  opacity: 1;
  transform: translateY(0px);
}
#page-user #left {
  float: left;
  width: 50%;
  padding: 0 30px 0 0px;
}
@media screen and (max-width: 768px) {
  #page-user #left {
    float: none;
    padding: 0;
    width: 90%;
    margin: 15px auto;
  }
}
#page-user #right {
  float: left;
  width: 50%;
  padding: 0 0px 0 30px;
}
@media screen and (max-width: 768px) {
  #page-user #right {
    float: none;
    padding: 0;
    width: 90%;
    margin: 15px auto;
  }
}
#page-login #left {
  float: left;
  width: 50%;
  padding: 0 30px 0 0px;
}
@media screen and (max-width: 768px) {
  #page-login #left {
    float: none;
    padding: 0;
    width: 90%;
    margin: 15px auto;
  }
}
#page-login #right {
  float: left;
  width: 50%;
  padding: 0 0px 0 30px;
}
@media screen and (max-width: 768px) {
  #page-login #right {
    float: none;
    padding: 0;
    width: 90%;
    margin: 15px auto;
  }
}
#page-createProjet .craue_formflow_buttons {
  padding: 30px 0;
  text-align: center;
}
#page-createProjet .craue_formflow_buttons button {
  display: inline-block;
  margin: 5px 8px;
  float: none;
}
#page-createProjet .project-image-previex {
  max-width: 450px;
}
#page-createProjet a.add_counterpart_link {
  background: #f07818;
  margin-top: 20px;
}
#page-createProjet label {
  font-size: 1.328125em;
  letter-spacing: 1px;
  font-family: "oswald";
  text-transform: uppercase;
}
#page-createProjet form h4 label {
  color: #f07818;
}
#page-createProjet form h5 label {
  color: #4f2f1b;
  text-transform: uppercase;
}
#page-createProjet form ul.a2lix_translationsLocales li,
#page-createProjet form ul.a2lix_translationsLocales li a {
  letter-spacing: 1px;
  font-family: "oswald";
  text-transform: uppercase;
  color: #aaa;
}
#page-createProjet form ul.a2lix_translationsLocales li.active,
#page-createProjet form ul.a2lix_translationsLocales li.active a {
  color: #f07818;
}
#page-createProjet form ul.a2lix_translationsLocales li.error,
#page-createProjet form ul.a2lix_translationsLocales li.error a {
  color: red;
}
#page-createProjet form ul li {
  list-style-type: none;
}
#page-createProjet .padding-15 {
  padding: 15px;
}
#page-createProjet form {
  color: #5e412f;
}
#page-createProjet form div .btn {
  margin: 10px 0;
}
#page-createProjet form .project-image-preview img {
  max-width: 370px;
}
#page-createProjet form .input-group-addon {
  color: #fff;
}
#page-createProjet ul.contreparties li {
  margin: 10px auto;
}
#page-contribuer .contrepartie-line {
  cursor: pointer;
  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;
}
#page-contribuer .contrepartie-line:hover {
  background: rgba(255, 255, 255, 0.15);
}
#page-contribuer .contrepartie-line.selected {
  background: #fff;
  color: #f07818;
}
#page-aiderLeMonde .catIcon {
  width: 95px;
  height: 95px;
  margin: 10px;
  display: inline-block;
}
#page-aiderLeMonde .catIcon-alimentaire {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_alimentaire.png");
}
#page-aiderLeMonde .catIcon-alimentaire.act,
#page-aiderLeMonde .catIcon-alimentaire:hover {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_alimentaire_act.png");
}
#page-aiderLeMonde .catIcon-environnement {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_environnement.png");
}
#page-aiderLeMonde .catIcon-environnement.act,
#page-aiderLeMonde .catIcon-environnement:hover {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_environnement_act.png");
}
#page-aiderLeMonde .catIcon-social {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_social.png");
}
#page-aiderLeMonde .catIcon-social.act,
#page-aiderLeMonde .catIcon-social:hover {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_social_act.png");
}
#page-aiderLeMonde .catIcon-artisanat {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_artisanat.png");
}
#page-aiderLeMonde .catIcon-artisanat.act,
#page-aiderLeMonde .catIcon-artisanat:hover {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_artisanat_act.png");
}
#page-aiderLeMonde .catIcon-sport {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_sport.png");
}
#page-aiderLeMonde .catIcon-sport.act,
#page-aiderLeMonde .catIcon-sport:hover {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_sport_act.png");
}
#page-aiderLeMonde .catIcon-art {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_art.png");
}
#page-aiderLeMonde .catIcon-art.act,
#page-aiderLeMonde .catIcon-art:hover {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_art_act.png");
}
#page-aiderLeMonde .catIcon-loisirs {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_loisirs.png");
}
#page-aiderLeMonde .catIcon-loisirs.act,
#page-aiderLeMonde .catIcon-loisirs:hover {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_loisirs_act.png");
}
#page-aiderLeMonde .catIcon-developpement {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_developpement.png");
}
#page-aiderLeMonde .catIcon-developpement.act,
#page-aiderLeMonde .catIcon-developpement:hover {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_developpement_act.png");
}
#page-aiderLeMonde .catIcon-sante {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_sante.png");
}
#page-aiderLeMonde .catIcon-sante.act,
#page-aiderLeMonde .catIcon-sante:hover {
  background-image: url("../../bundles/jamain/img/icones/bigIcon_sante_act.png");
}
#page-aiderLeMonde .categorieDiv {
  display: none;
}
#page-aiderLeMonde .categorieDiv.act {
  display: block;
}
@media (max-width: 768px) {
  #page-aiderLeMonde {
    /* test font-size:calc(12px + 1.5vw); */
    /* test font-size:calc(12px + 1.5vw); */
  }
  #page-aiderLeMonde h1 {
    font-size: calc(20px);
  }
  #page-aiderLeMonde h2 {
    font-size: calc(20px);
  }
}
#page-blog-article .blogTitle {
  background-size: cover;
  height: 600px;
}
#page-blog-article .blogTitle h1 {
  font-size: 2.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "oswald";
  text-shadow: 0 5px 7px #7b9cbb;
}
#page-blog-article img {
  max-width: 640px;
  display: block;
  margin: 0 auto;
}
#page-partenaires h1 {
  margin-bottom: 50px;
}
#page-partenaires img.partenaire {
  border-radius: 5px;
}
#page-partenaires hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(left, #f0f0f0, #8c8c8c, #f0f0f0);
}
.slideshow-container {
  width: 97%;
  margin: auto;
  height: 100%;
  position: relative;
}
.slideshow-container .centerPoints {
  position: absolute;
  bottom: -30px;
  width: 100%;
  height: 30px;
}
@media screen and (max-width: 768px) {
  .slideshow-container .centerPoints {
    bottom: -20px;
  }
}
.slideshow-container .centerPoints .slideshow-points {
  display: inline-block;
}
.slideshow-container .centerPoints .slideshow-points .sliderPoint {
  width: 29px;
  height: 29px;
  display: inline-block;
  background: url("../img/point.png");
  margin: 0 3px;
  cursor: pointer;
}
.slideshow-container .centerPoints .slideshow-points .sliderPoint.act {
  background: url("../img/point_act.png");
}
.slideshow-container .control {
  cursor: pointer;
  width: 36px;
  height: 59px;
  display: inline-block;
  position: absolute;
  top: 40%;
  z-index: 3;
}
.slideshow-container .control.arrowRight {
  background: url("../img/rightArrow.png");
  right: -40px;
}
.slideshow-container .control.arrowLeft {
  background: url("../img/leftArrow.png");
  left: -50px;
}
.slideshow-container .slideshow {
  position: relative;
  overflow: hidden;
}
.slideshow-container .slideshow .container {
  position: relative;
  z-index: 1;
}
.slideshow-container .slideshow .container .slide {
  float: left;
  position: relative;
  left: 0px;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .slideshow-container .slideshow .container .slide {
    padding: 0 20px;
  }
}
.slideshow-container .slideshow .container .slide .fixedContent {
  position: absolute;
  z-index: 2;
  width: 100%;
}
/* entire container, keeps perspective */
.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
}
/* flip the pane when hovered */
.flip-container:hover .flipper,
.flip-container.hover .flipper {
  -webkit-transform: rotatey(180deg);
  -moz-transform: rotatey(180deg);
  -ms-transform: rotatey(180deg);
  -o-transform: rotatey(180deg);
  transform: rotatey(180deg);
}
.flip-container,
.front,
.back {
  width: 100%;
  height: 60px;
  cursor: pointer;
}
/* flip speed goes here */
.flipper {
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -ms-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
}
/* hide back of pane during swap */
.front,
.back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: table;
}
/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  -webkit-transform: rotatey(0deg);
  -moz-transform: rotatey(0deg);
  -ms-transform: rotatey(0deg);
  -o-transform: rotatey(0deg);
  transform: rotatey(0deg);
}
/* back, initially hidden pane */
.back {
  -webkit-transform: rotatey(180deg);
  -moz-transform: rotatey(180deg);
  -ms-transform: rotatey(180deg);
  -o-transform: rotatey(180deg);
  transform: rotatey(180deg);
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  color: white;
}
a.back {
  padding-left: 5px;
  padding-right: 5px;
}
a.back.btn:hover {
  background: #fff;
}
a.back.btn {
  background-color: #f07818;
}
@-webkit-keyframes clouds {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@-moz-keyframes clouds {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@-ms-keyframes clouds {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@-o-keyframes clouds {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@keyframes clouds {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
.flip_box.flip .back {
  z-index: 1000;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform: rotateX(0deg) rotateY(0deg);
  transform: rotateX(0deg) rotateY(0deg);
}
.flip_box .back {
  float: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  width: inherit;
  height: inherit;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-179deg);
  transform: rotateY(-179deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.flip_box .front {
  float: none;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 400;
  width: inherit;
  height: inherit;
  text-align: center;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform: rotateX(0deg) rotateY(0deg);
  transform: rotateX(0deg) rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding-bottom: 0px !important;
}
.flip_box.flip .front {
  z-index: 900;
  border-color: #eee;
  background: #333;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
#fountainG {
  position: relative;
  width: 240px;
  height: 29px;
  margin: 50px auto;
}
.fountainG {
  position: absolute;
  top: 0;
  background-color: #F07918;
  width: 29px;
  height: 29px;
  -moz-animation-name: bounce_fountainG;
  -moz-animation-duration: 0.8s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: normal;
  -moz-transform: scale(0.3);
  -moz-border-radius: 19px;
  -webkit-animation-name: bounce_fountainG;
  -webkit-animation-duration: 0.8s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
  -webkit-transform: scale(0.3);
  -webkit-border-radius: 19px;
  -ms-animation-name: bounce_fountainG;
  -ms-animation-duration: 0.8s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: normal;
  -ms-transform: scale(0.3);
  -ms-border-radius: 19px;
  -o-animation-name: bounce_fountainG;
  -o-animation-duration: 0.8s;
  -o-animation-iteration-count: infinite;
  -o-animation-direction: normal;
  -o-transform: scale(0.3);
  -o-border-radius: 19px;
  animation-name: bounce_fountainG;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  transform: scale(0.3);
  border-radius: 19px;
}
#fountainG_1 {
  left: 0;
  -moz-animation-delay: 0.32s;
  -webkit-animation-delay: 0.32s;
  -ms-animation-delay: 0.32s;
  -o-animation-delay: 0.32s;
  animation-delay: 0.32s;
}
#fountainG_2 {
  left: 30px;
  -moz-animation-delay: 0.4s;
  -webkit-animation-delay: 0.4s;
  -ms-animation-delay: 0.4s;
  -o-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
#fountainG_3 {
  left: 60px;
  -moz-animation-delay: 0.48s;
  -webkit-animation-delay: 0.48s;
  -ms-animation-delay: 0.48s;
  -o-animation-delay: 0.48s;
  animation-delay: 0.48s;
}
#fountainG_4 {
  left: 90px;
  -moz-animation-delay: 0.56s;
  -webkit-animation-delay: 0.56s;
  -ms-animation-delay: 0.56s;
  -o-animation-delay: 0.56s;
  animation-delay: 0.56s;
}
#fountainG_5 {
  left: 120px;
  -moz-animation-delay: 0.64s;
  -webkit-animation-delay: 0.64s;
  -ms-animation-delay: 0.64s;
  -o-animation-delay: 0.64s;
  animation-delay: 0.64s;
}
#fountainG_6 {
  left: 150px;
  -moz-animation-delay: 0.72s;
  -webkit-animation-delay: 0.72s;
  -ms-animation-delay: 0.72s;
  -o-animation-delay: 0.72s;
  animation-delay: 0.72s;
}
#fountainG_7 {
  left: 180px;
  -moz-animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
  -ms-animation-delay: 0.8s;
  -o-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
#fountainG_8 {
  left: 210px;
  -moz-animation-delay: 0.88s;
  -webkit-animation-delay: 0.88s;
  -ms-animation-delay: 0.88s;
  -o-animation-delay: 0.88s;
  animation-delay: 0.88s;
}
@-moz-keyframes bounce_fountainG {
  0% {
    -moz-transform: scale(1);
    background-color: #F07918;
  }
  100% {
    -moz-transform: scale(0.3);
    background-color: #5E412F;
  }
}
@-webkit-keyframes bounce_fountainG {
  0% {
    -webkit-transform: scale(1);
    background-color: #F07918;
  }
  100% {
    -webkit-transform: scale(0.3);
    background-color: #5E412F;
  }
}
@-ms-keyframes bounce_fountainG {
  0% {
    -ms-transform: scale(1);
    background-color: #F07918;
  }
  100% {
    -ms-transform: scale(0.3);
    background-color: #5E412F;
  }
}
@-o-keyframes bounce_fountainG {
  0% {
    -o-transform: scale(1);
    background-color: #F07918;
  }
  100% {
    -o-transform: scale(0.3);
    background-color: #5E412F;
  }
}
@keyframes bounce_fountainG {
  0% {
    transform: scale(1);
    background-color: #F07918;
  }
  100% {
    transform: scale(0.3);
    background-color: #5E412F;
  }
}
body {
  padding-top: 0px;
  /* Required padding for .navbar-fixed-top. Remove if using .navbar-static-top. Change if height of navigation changes. */
  background-color: #fff;
  font-family: 'lato';
}
.flx {
  display: flex;
}
.flx-center {
  align-items: center;
}
.flx-space {
  justify-content: space-between;
}
flx-nowrap {
  flex-wrap: nowrap;
}
flx-wrap {
  flex-wrap: wrap;
}
.flex-col {
  display: flex;
  display: -webkit-flex;
  flex: 1 100%;
  flex-flow: column nowrap;
}
.flex-grow {
  display: flex;
  -webkit-flex: 2;
  flex: 2;
}
/* Header Image Background - Change the URL below to your image path (example: ../images/background.jpg) */
.btn.outline {
  background: none;
}
.btn.default.outline {
  border: 2px solid #fff;
}
.navbar {
  background: none;
  /* padding-top: 16px; */
  padding-right: 20px;
}
.navbar-default {
  border: none;
}
.navbar-default .navbar-nav > li > a {
  color: #fff;
}
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #fff;
}
.navbar li a {
  font-size: 20px;
  font-family: 'Oswald', sans-serif;
  text-shadow: 0 0 5px grey;
  color: #fff;
  display: inline;
  padding: 0px;
  /* vertical-align: middle; */
}
.sprite {
  display: inline-block;
  margin: 0 0px 0 10px;
  vertical-align: middle;
}
#slogan {
  height: 217px;
  padding-top: 40px;
  background-image: url('../img/slogan-deco.png');
  background-repeat: no-repeat;
  background-position: center center;
}
.business-header {
  height: 650px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.business-header video {
  filter: brightness(0.7);
}
.search-bar {
  height: 50px;
  background-color: #ff9600;
}
/* Customize the text color and shadow color and to optimize text legibility. */
.tagline {
  text-shadow: 0 0 10px #000;
  color: #fff;
}
#welcome {
  margin-top: 50px;
  border: 1px solid #8aaed1;
  padding: 15px;
}
#welcome h2 {
  color: #535353;
}
.welcome-button {
  display: block;
  color: #535353;
  text-decoration: none;
  width: 100%;
  font-size: 18px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #d8d8d8;
}
.welcome-button:hover {
  background: #f07818;
}
.img-center {
  margin: 0 auto;
}
/***** item *****/
.projetThumb {
  background-color: #fff;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}
.projetThumb img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.projetThumb h4 a {
  color: #535353;
}
.projetThumb a img {
  width: 100%;
}
.projetThumb .item-body {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
}
.projetThumb .item-body .push {
  flex-grow: 99;
}
.projetThumb .item-footer {
  padding: 5px;
  color: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.projetThumb .item-footer.success {
  background: #3ab13a;
}
.projetThumb .item-footer.closed {
  background-color: #000;
}
.projetThumb .item-footer.current {
  background: #f07818;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border: none;
}
.table > tbody > tr.nopadding-b > td {
  padding-bottom: 0;
}
.table > tbody > tr.nopadding-t > td {
  padding-top: 0;
}
.progress {
  overflow: visible;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: none;
  border-radius: 0;
  width: 100%;
  margin: 0;
}
.progress .progress-bar {
  float: none;
  position: relative;
}
.progress.progress-xxs {
  height: 3px;
  margin-top: 8px;
}
.progress.progress-sm {
  height: 14px;
}
.carousel {
  margin: 30px auto 30px auto;
}
.owl-theme .owl-controls .owl-buttons div {
  padding: 0;
}
.controlls-over .owl-controls .owl-prev {
  position: absolute;
  top: 50%;
  margin-top: -28px;
  right: auto;
  left: 0px;
  zoom: 1;
  width: 36px;
  height: 36px;
  opacity: 0;
}
.controlls-over .owl-controls .owl-next {
  position: absolute;
  top: 50%;
  margin-top: -28px;
  left: auto;
  right: 0px;
  zoom: 1;
  width: 36px;
  height: 36px;
  opacity: 0;
}
/** home - carte **/
#carte {
  background-color: #8aaed1;
  border-top-left-radius: 700px 40px;
  border-top-right-radius: 700px 40px;
  border-bottom-left-radius: 700px 40px;
  border-bottom-right-radius: 700px 40px;
  padding: 60px 0px 50px 0px;
}
#carte,
#carte h1,
#carte a,
#carte a:hover {
  color: #fff;
  text-decoration: none;
}
#carte a#globe {
  padding-bottom: 50px;
  font-weight: bold;
  font-size: 1.2em;
}
/**** footer  ***/
#footer {
  background-color: #5e412f;
  margin-bottom: 0px;
  padding: 15px 5px 15px 5px;
  font-family: 'Lato', sans-serif;
  font-stretch: normal;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  line-height: 18px;
  color: #fcebb6;
}
footer div.row nav {
  /* width: 25%; */
  float: left;
}
footer nav ul li a,
footer nav ul li a:hover {
  color: #fcebb6;
  font-size: 15px;
  font-weight: bold;
  margin: 3px 0 3px 0;
  display: block;
  text-shadow: rgba(0, 0, 0, 0.14902) 0px 0px 1px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: transparent;
}
/**** carousel ****/
.owl-item {
  padding: 0 2px 0 2px;
}
.owl-carousel img {
  width: 98%;
  margin: 0 auto;
  display: inline-block;
}
.owl-carousel.buttons-autohide .owl-buttons {
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.owl-carousel.buttons-autohide:hover .owl-buttons {
  filter: alpha(opacity=100);
  opacity: 1;
}
.owl-theme .owl-controls .owl-buttons div {
  color: #121212;
  background: none;
  border: none;
  opacity: 1;
  filter: alpha(opacity=100);
  margin: 0px -40px 0px -40px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.owl-prev .fa,
.owl-next .fa {
  font-size: 34px;
}
/* buttons bottom */
.owl-carousel.buttons-bottom .owl-controls {
  top: auto !important;
}
.owl-carousel.buttons-bottom .owl-next,
.owl-carousel.buttons-bottom .owl-prev {
  margin-top: -20px !important;
}
table.projet > tbody > tr > td {
  vertical-align: middle;
}
table.projet > tbody > tr > td strong.montant {
  font-size: calc(19px);
}
@media (max-width: 768px) {
  /* test font-size:calc(12px + 1.5vw); */
  #slogan h1 {
    font-size: 5vw;
  }
  #slogan h2 {
    font-size: 4vw;
  }
  h1 {
    font-size: calc(26px);
  }
}
.twitter-typeahead {
  width: 100%;
}
/* Global Structure */
/* General Clases */
