#page-user{
	#left{
		float: left;
		width: 50%;
		padding: 0 30px 0 0px;

		@media screen and (max-width: @responsiveThirdThresold){
			float: none;
			padding: 0;
			width: 90%;
			margin: 15px auto;
		}
	}
	#right{
		float: left;
		width: 50%;
		padding: 0 0px 0 30px;

		@media screen and (max-width: @responsiveThirdThresold){
			float: none;
			padding: 0;
			width: 90%;
			margin: 15px auto;
		}
	}
}