#page-partenaires{

    h1 {
        margin-bottom: 50px;
    }
    img.partenaire {
        border-radius: 5px;
    }

    hr {
        border: 0;
        height: 1px;
        background-image: linear-gradient(left, #f0f0f0, #8c8c8c, #f0f0f0);
    }
}
