/* forms */

label{
	line-height: 22px;
}


input:not([type="submit"]),
textarea,
.select,
select
{
	.lato;
	display: inline-block;
	background: fade(white, 80%);
	padding: 5px;
	height: 30px;
	.standard;
	color: @greyDark;
	border: 1px solid transparent;
	-webkit-box-shadow: 0px 0px 0 transparent;
	-moz-box-shadow: 0px 0px 0 transparent;
	box-shadow: 0px 0px 0 transparent;
	.transition(all);
	border: 1px solid #eee;
}

input.uncorrect:not([type="submit"]),
textarea.uncorrect,
select.uncorrect
{
	border: 1px solid #CC2C2C;
}
input.correct:not([type="submit"]),
textarea.correct,
select.correct
{
	border: 1px solid #8FDC95;
}

input:not([type="submit"])::-webkit-input-placeholder, 
textarea::-webkit-input-placeholder, 
.select{
	color: @greyLight;
}

input:not([type="submit"]):hover,
textarea:hover,
select:hover{
	border: 1px solid fade(@secondary, 50%);
	background: fade(white, 90%);
}

input:not([type="submit"]):focus,
textarea:focus,
select:focus{
	-webkit-box-shadow: 0px 0px 0 transparent;
	-moz-box-shadow: 0px 0px 0 transparent;
	box-shadow: 0px 0px 0 transparent;
	border: 1px solid @secondary;
	outline: none;
	background: fade(white, 100%);
}


input[type="checkbox"]{
	vertical-align: middle;
}

input[readonly="readonly"]:not([type="submit"]){
	background-color: darken(@greyLightest, 5%);
}

textarea{
	height: 90px;
	resize: none;
}
.form-100{
	input:not([type="submit"]):not([type="checkbox"]),
	textarea,
	.select,
	select{
		width: 100%;
	}
}




.doubleForm{
	fieldset:first-child{
		padding-right: 20px;
	}
	fieldset:last-child{
		padding-left: 20px;
	}
	fieldset{
		width: 50%;
		float: left;

		legend{
		}
	}
	clear:both;
}

.listForm{
	ul{
		li{	
			>div{
				margin: 20px 0 10px;
				label{
					display: inline-block;
				}

				.error{
					display: inline;
				}

				input{

				}
				}
		}
	}
}


.tableForm{
	table{
		.table-100;
		.smallPaddingTable;

		td, th{
			padding-top: 5px;
			padding-bottom: 5px;
			vertical-align: middle;


			input:not([type="submit"]):not([type="checkbox"]),
			textarea,
			.select,
			select,
			.reSelectContainer
			{
				position: relative;
				bottom: 3px;
				float: left;
				min-width: 150px;
			}


			label{
				float: left;
				margin-right: 10px;
			}
			button:not(.noBlock), input[type=submit]:not(.noBlock){
				display: block;
				width: 100%;
				padding: 6px;
				margin: 6px 0;
			}
		}
		td{
		}
	}
}

.searchInput{
	.greyDarkestBlock;
	padding-right: 30px;
	display: inline-block;
	input[type=search]{
		background: white;
	}
	background-image: url("../img/icons.png");
	background-repeat: no-repeat;
	background-position: 126px -666px;
}

.searchInput:after{
	.greyDarkestBlock;
	width: 20px;
	height: 20px;
	.icon-search;
}

.genemuDatePicker, .datePicker{
	select{
		display: inline-block;
		width: 70px;
		min-width: 0 !important;
	}
	button{
		padding: 3px 8px;
		margin-left: 3px;
	}
}

.starRequired{
	label.required:after{
		content: "*";
		.mid;
	}
}

.moneyInput-container{
	.currency{
		.big;
		.bold;
		position: relative;
		right: 33px;
		top: 3px;
	}
	.moneyInput{
		width: 100px;
		padding: 8px 10px;
		padding-right: 25px;
		height: auto;
		.bold;
		.rounded(5px);
		color: @mainVeryDark;
	}
}