#page-faq{
	section{
		padding: 30px;
		.centeredContainer{
			.hugePadding;
		}
		h2{
			font-size: 30px;
			.bold;
			margin: 25px 0;
			position: relative;
			right: 27px;
			i{
				position: relative;
				right: 30px;
			}
		}
		h3{
			.mid;
			.bold;
			margin: 15px 0;
			.pointer;
		}
		.container{
			margin-bottom: 30px;
			.mid;
			display: none;
		}
	}
}
