

// Animations
/* entire container, keeps perspective */
.flip-container {
 -webkit-perspective : 1000;
 -moz-perspective    : 1000;
 -ms-perspective     : 1000;
 perspective         : 1000;
}
/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
 -webkit-transform : rotatey(180deg);
 -moz-transform    : rotatey(180deg);
 -ms-transform     : rotatey(180deg);
 -o-transform      : rotatey(180deg);
 transform         : rotatey(180deg);
}
.flip-container, .front, .back {
 width  : 100%;
 height : 60px;
	cursor: pointer;
}
/* flip speed goes here */
.flipper {
 -webkit-transition      : 0.6s;
 -moz-transition         : 0.6s;
 -ms-transition          : 0.6s;
 -o-transition           : 0.6s;
 transition              : 0.6s;
 -webkit-transform-style : preserve-3d;
 -moz-transform-style    : preserve-3d;
 -ms-transform-style     : preserve-3d;
 -o-transform-style      : preserve-3d;
 transform-style         : preserve-3d;
 position                : relative;
}
/* hide back of pane during swap */
.front, .back {
 -webkit-backface-visibility : hidden;
 -moz-backface-visibility    : hidden;
 -ms-backface-visibility     : hidden;
 backface-visibility         : hidden;
 position                    : absolute;
 top                         : 0;
 left                        : 0;
 display                     : table;
}
/* front pane, placed above back */
.front {
 z-index           : 2;
 /* for firefox 31 */
 -webkit-transform : rotatey(0deg);
 -moz-transform    : rotatey(0deg);
 -ms-transform     : rotatey(0deg);
 -o-transform      : rotatey(0deg);
 transform         : rotatey(0deg);
}
/* back, initially hidden pane */
.back {
 -webkit-transform     : rotatey(180deg);
 -moz-transform        : rotatey(180deg);
 -ms-transform         : rotatey(180deg);
 -o-transform          : rotatey(180deg);
 transform             : rotatey(180deg);
 -moz-border-radius    : 15px;
 -webkit-border-radius : 15px;
 border-radius         : 15px;
 color                 : white;
}
a.back{
	padding-left: 5px;
	padding-right: 5px;
}
a.back.btn:hover{
	background: @main;
}
a.back.btn{
	background-color      : #f07818;
}


@-webkit-keyframes clouds {
	from {
		background-position : 0 0;
	}
	to {
		background-position : 100% 0;
	}
}
@-moz-keyframes clouds {
	from {
		background-position : 0 0;
	}
	to {
		background-position : 100% 0;
	}
}
@-ms-keyframes clouds {
	from {
		background-position : 0 0;
	}
	to {
		background-position : 100% 0;
	}
}
@-o-keyframes clouds {
	from {
		background-position : 0 0;
	}
	to {
		background-position : 100% 0;
	}
}
@keyframes clouds {
	from {
		background-position : 0 0;
	}
	to {
		background-position : 100% 0;
	}
}



.flip_box {
}
.flip_box.flip .back {
 z-index           : 1000;
 -webkit-transform : rotateX(0deg) rotateY(0deg);
 -moz-transform    : rotateX(0deg) rotateY(0deg);
 transform         : rotateX(0deg) rotateY(0deg);
}
.flip_box .back {
 float                       : none;
 position                    : absolute;
 top                         : 0;
 left                        : 0;
 z-index                     : 300;
 width                       : inherit;
 height                      : inherit;
 -webkit-transform           : rotateY(-180deg);
 -moz-transform              : rotateY(-179deg);
 transform                   : rotateY(-179deg);
 -webkit-transform-style     : preserve-3d;
 -moz-transform-style        : preserve-3d;
 transform-style             : preserve-3d;
 -webkit-backface-visibility : hidden;
 -moz-backface-visibility    : hidden;
 backface-visibility         : hidden;
 -webkit-transition          : all .4s ease-in-out;
 transition                  : all .4s ease-in-out;
}

.flip_box .front {
 float                       : none;
 position                    : absolute !important;
 top                         : 0;
 left                        : 0;
 z-index                     : 400;
 width                       : inherit;
 height                      : inherit;
 text-align                  : center;
 -webkit-transform           : rotateX(0deg) rotateY(0deg);
 -moz-transform              : rotateX(0deg) rotateY(0deg);
 transform                   : rotateX(0deg) rotateY(0deg);
 -webkit-transform-style     : preserve-3d;
 -moz-transform-style        : preserve-3d;
 transform-style             : preserve-3d;
 -webkit-backface-visibility : hidden;
 -moz-backface-visibility    : hidden;
 backface-visibility         : hidden;
 -webkit-transition          : all .4s ease-in-out;
 transition                  : all .4s ease-in-out;
 padding-bottom              : 0px !important;
}
.flip_box.flip .front {
 z-index           : 900;
 border-color      : #eee;
 background        : #333;
 box-shadow        : 0 15px 50px rgba(0, 0, 0, 0.2);
 -webkit-transform : rotateY(180deg);
 -moz-transform    : rotateY(180deg);
 transform         : rotateY(180deg);
}


#fountainG{
position:relative;
width:240px;
height:29px;
margin: 50px auto;
}

.fountainG{
position:absolute;
top:0;
background-color:#F07918;
width:29px;
height:29px;
-moz-animation-name:bounce_fountainG;
-moz-animation-duration:0.8s;
-moz-animation-iteration-count:infinite;
-moz-animation-direction:normal;
-moz-transform:scale(.3);
-moz-border-radius:19px;
-webkit-animation-name:bounce_fountainG;
-webkit-animation-duration:0.8s;
-webkit-animation-iteration-count:infinite;
-webkit-animation-direction:normal;
-webkit-transform:scale(.3);
-webkit-border-radius:19px;
-ms-animation-name:bounce_fountainG;
-ms-animation-duration:0.8s;
-ms-animation-iteration-count:infinite;
-ms-animation-direction:normal;
-ms-transform:scale(.3);
-ms-border-radius:19px;
-o-animation-name:bounce_fountainG;
-o-animation-duration:0.8s;
-o-animation-iteration-count:infinite;
-o-animation-direction:normal;
-o-transform:scale(.3);
-o-border-radius:19px;
animation-name:bounce_fountainG;
animation-duration:0.8s;
animation-iteration-count:infinite;
animation-direction:normal;
transform:scale(.3);
border-radius:19px;
}

#fountainG_1{
left:0;
-moz-animation-delay:0.32s;
-webkit-animation-delay:0.32s;
-ms-animation-delay:0.32s;
-o-animation-delay:0.32s;
animation-delay:0.32s;
}

#fountainG_2{
left:30px;
-moz-animation-delay:0.4s;
-webkit-animation-delay:0.4s;
-ms-animation-delay:0.4s;
-o-animation-delay:0.4s;
animation-delay:0.4s;
}

#fountainG_3{
left:60px;
-moz-animation-delay:0.48s;
-webkit-animation-delay:0.48s;
-ms-animation-delay:0.48s;
-o-animation-delay:0.48s;
animation-delay:0.48s;
}

#fountainG_4{
left:90px;
-moz-animation-delay:0.56s;
-webkit-animation-delay:0.56s;
-ms-animation-delay:0.56s;
-o-animation-delay:0.56s;
animation-delay:0.56s;
}

#fountainG_5{
left:120px;
-moz-animation-delay:0.64s;
-webkit-animation-delay:0.64s;
-ms-animation-delay:0.64s;
-o-animation-delay:0.64s;
animation-delay:0.64s;
}

#fountainG_6{
left:150px;
-moz-animation-delay:0.72s;
-webkit-animation-delay:0.72s;
-ms-animation-delay:0.72s;
-o-animation-delay:0.72s;
animation-delay:0.72s;
}

#fountainG_7{
left:180px;
-moz-animation-delay:0.8s;
-webkit-animation-delay:0.8s;
-ms-animation-delay:0.8s;
-o-animation-delay:0.8s;
animation-delay:0.8s;
}

#fountainG_8{
left:210px;
-moz-animation-delay:0.88s;
-webkit-animation-delay:0.88s;
-ms-animation-delay:0.88s;
-o-animation-delay:0.88s;
animation-delay:0.88s;
}

@-moz-keyframes bounce_fountainG{
0%{
-moz-transform:scale(1);
background-color:#F07918;
}

100%{
-moz-transform:scale(.3);
background-color:#5E412F;
}

}

@-webkit-keyframes bounce_fountainG{
0%{
-webkit-transform:scale(1);
background-color:#F07918;
}

100%{
-webkit-transform:scale(.3);
background-color:#5E412F;
}

}

@-ms-keyframes bounce_fountainG{
0%{
-ms-transform:scale(1);
background-color:#F07918;
}

100%{
-ms-transform:scale(.3);
background-color:#5E412F;
}

}

@-o-keyframes bounce_fountainG{
0%{
-o-transform:scale(1);
background-color:#F07918;
}

100%{
-o-transform:scale(.3);
background-color:#5E412F;
}

}

@keyframes bounce_fountainG{
0%{
transform:scale(1);
background-color:#F07918;
}

100%{
transform:scale(.3);
background-color:#5E412F;
}

}
