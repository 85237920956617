#page-commentCaMarche{
	#parallax-1{
		background-image: url("../../bundles/jamain/img/commentCaMarche/parallax1.jpg");
		height: 600px;
	}
	#parallax-2{
		background-image: url("../../bundles/jamain/img/commentCaMarche/parallax2.jpg");
		height: 600px;
	}
}
