/* Fonts */

@font-face {
    font-family: 'latoLight';
    src: url('../fonts/lato/Lato-Light.eot');
    src: url('../fonts/lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-Light.woff2') format('woff2'),
         url('../fonts/lato/Lato-Light.woff') format('woff'),
         url('../fonts/lato/Lato-Light.ttf') format('truetype'),
         url('../fonts/lato/Lato-Light.svg#asapbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url('../fonts/lato/Lato-Regular.eot');
    src: url('../fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-Regular.woff2') format('woff2'),
         url('../fonts/lato/Lato-Regular.woff') format('woff'),
         url('../fonts/lato/Lato-Regular.ttf') format('truetype'),
         url('../fonts/lato/Lato-Regular.svg#asapbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato';
    src: url('../fonts/lato/Lato-Bold.eot');
    src: url('../fonts/lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-Bold.woff2') format('woff2'),
         url('../fonts/lato/Lato-Bold.woff') format('woff'),
         url('../fonts/lato/Lato-Bold.ttf') format('truetype'),
         url('../fonts/lato/Lato-Bold.svg#asapbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'latoBlack';
    src: url('../fonts/lato/Lato-Black.eot');
    src: url('../fonts/lato/Lato-Black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/Lato-Black.woff2') format('woff2'),
         url('../fonts/lato/Lato-Black.woff') format('woff'),
         url('../fonts/lato/Lato-Black.ttf') format('truetype'),
         url('../fonts/lato/Lato-Black.svg#asapbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'oswaldLight';
    src: url('../fonts/oswald/Oswald-Light-webfont.eot');
    src: url('../fonts/oswald/Oswald-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oswald/Oswald-Light-webfont.woff2') format('woff2'),
         url('../fonts/oswald/Oswald-Light-webfont.woff') format('woff'),
         url('../fonts/oswald/Oswald-Light-webfont.ttf') format('truetype'),
         url('../fonts/oswald/Oswald-Light-webfont.svg#asapbold') format('svg');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'oswald';
    src: url('../fonts/oswald/Oswald-Bold-webfont.eot');
    src: url('../fonts/oswald/Oswald-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oswald/Oswald-Bold-webfont.woff2') format('woff2'),
         url('../fonts/oswald/Oswald-Bold-webfont.woff') format('woff'),
         url('../fonts/oswald/Oswald-Bold-webfont.ttf') format('truetype'),
         url('../fonts/oswald/Oswald-Bold-webfont.svg#asapbold_italic') format('svg');
    font-weight: bold;
    font-style: normal;

}


@font-face {
    font-family: 'oswald';
    src: url('../fonts/oswald/Oswald-Regular-webfont.eot');
    src: url('../fonts/oswald/Oswald-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/oswald/Oswald-Regular-webfont.woff2') format('woff2'),
         url('../fonts/oswald/Oswald-Regular-webfont.woff') format('woff'),
         url('../fonts/oswald/Oswald-Regular-webfont.ttf') format('truetype'),
         url('../fonts/oswald/Oswald-Regular-webfont.svg#asapitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}


.oswald{
    letter-spacing: 1px;
	font-family: "oswald";
}
.oswaldLight{
    font-family: "oswaldLight";
}
.lato{
    font-family: "lato";
}
.latoLight{
    font-family: "latoLight";
}
.latoBlack{
	font-family: "latoBlack";
}
.bold{
    font-weight: bold;
}


body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	.lato;
	font-smooth:always;
	-webkit-font-smoothing: antialiased;
}
