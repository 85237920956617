#page-contact{
	section{
		background: url("../img/contact/bg.jpg");
		#contactForm{
			max-width:  500px;
			width: 50%;
			float: left;
		}
		#stamps{
			width: 50%;
			float: right;
		}
	}
}