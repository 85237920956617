#page-contribuer{
	.contrepartie-line{
		.pointer;
		.transition(background);
	}
	.contrepartie-line:hover{
		background: fade(@main, 15%);
	}
	.contrepartie-line.selected{
		background: @main;
		color: @orangeDark;
	}
}
