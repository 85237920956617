// .btn, input[type=submit], button{
// 	// background-color: @main;
// 	display: inline-block;
// 	padding: 15px 20px;
// 	color: white;
// 	text-shadow: 0px 0px 0px transparent;
// 	.transition(all);
// 	cursor: pointer;
// 	.uppercase;
// 	.lato;
// 	.mid;
// 	.center;

// 	.rounded(7px);
// 	border: 0;
// 	border-bottom: 2px solid @mainDark;
// 	outline: 0;
// }

// .btn:hover, input[type=submit]:hover, button:hover{
// 	background-color: @mainDark;
// 	color: white;
// }
.back.btn{
	border-bottom: none;
}

.btn-rounded.btn{
	border: none;
	.rounded(15px);
}

.btn.btn-grey, input[type=submit].btn-grey, button.btn-grey{
	background: @greyDark;
	border-bottom: none;
}

.btn.btn-grey:hover, input[type=submit].btn-grey:hover, button.btn-grey:hover{
	background: @greyDarkest;
}
div.btn.btn-grey:hover{
	background: @greyDark;
}


.btn.btn-passed, input[type=submit].btn-passed, button.btn-passed{
	background: @mainLightest;
	text-transform: none;
	color: @main;
	text-shadow: none;
}

.btn.btn-passed:hover, input[type=submit].btn-passed:hover, button.btn-passed:hover{
	background: darken(@mainLightest, 10%);
	color: darken(@main, 10%);
}

.btn.btn-white, input[type=submit].btn-white, button.btn-white{
	background: @white;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	.greyDark;
}

.btn.btn-white:hover, input[type=submit].btn-white:hover, button.btn-white:hover{
	.greyDarkest;
}

.btn.btn-inversed, input[type=submit].btn-inversed, button.btn-inversed{
	background: @main;
	color: @greyDarkest;
}
.btn.btn-inversed:hover, input[type=submit].btn-inversed:hover, button.btn-inversed:hover{
	color: @greyDark;
}

.btn-back:before{
	content: "< ";
	color: @main;
}


.btn.btn-mid{
	padding: 8px 12px;
	.tiny;
}

.btn.btn-small{
	padding: 4px 8px;
	.tiny;
}

.btn-block{
	display: block !important;
	width: 100%;
}
.btn-tiny{
	padding: 4px 8px;
	.tiny;
}

.btn.block{
	display: block;
}

.btn.btn-secondary{
	background-color: @main;
}
.btn.btn-secondary:hover{
	background-color: @mainDark;
}
.btn.btn-secondaryLight{
	background-color: @mainLightest;
}
.btn.btn-secondaryLight:hover{
	background-color: darken(@mainLightest, 10%)
}

.btn-arrow{
	background: @secondary url("../img/icons/rightArrow.png") no-repeat 95% 15px;
	padding-right: 40px;
}
.btn-arrow:hover{
	background-position: 97% 15px;
}

.btn:disabled{
	background: @greyLight;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
	cursor: auto;
}


.btn-onSecondary{
	background: transparent;
	.rounded(0);
	.bordered(2px);
	.standard;
	padding: 4px 15px;
	.transition(background);
}
.btn-onSecondary:hover{
	background: fade(white, 20%);
}

.btn-slide{
	.bordered(1px);
	letter-spacing: 1px;
	background: fade(white, 0%);
	border-top-left-radius: 25px;
	border-bottom-right-radius: 25px;
}
.btn-slide:hover{
	background: fade(white, 10%);
}

.btn-slide.btn-slide-mainVeryDark{
	.bordered;
	border-color: @mainVeryDark;
	border-width: 2px;
	color: @mainVeryDark !important;
}
.btn-slide.btn-slide-mainVeryDark:hover{
	color: @tertiary !important;
	background: @mainVeryDark;
}

// Prevent bug for revolution slider
.tp-static-layers .btn-slide{
	padding: 8px 30px !important;
	border: 1px solid #fff !important;
	font-size: 20px !important;
	line-height: 30px !important;
}

.btn-bigMarron{
	background: @mainVeryDark;
	color: @tertiary;
	.oswald;
	.uppercase;
	font-size: 35px;
	.center;
	padding: 20px;
}
.btn-bigMarron:hover, .btn-bigMarron.act{
	background: darken(@mainVeryDark, 5%);
	color: @tertiary;
}
.btn-bigMarron.btn-slide{
	border: none;
	color: @tertiary !important;
}

.left{
}
