/* glue: 0.11.0 hash: e197ecea5a */
        .sprite-pictos-picto-big-monde,
.sprite-pictos-picto-social,
.sprite-pictos-picto-environnement,
.sprite-pictos-picto-sport,
.sprite-pictos-picto-dev,
.sprite-pictos-picto-sante,
.sprite-pictos-picto-loisir,
.sprite-pictos-picto-artisanat,
.sprite-pictos-picto-alimentaire,
.sprite-pictos-picto-question,
.sprite-pictos-picto-heart,
.sprite-pictos-picto-monde,
.sprite-pictos-picto-loupe,
.sprite-pictos-picto-user,
.sprite-pictos-picto-rocket,
.sprite-pictos-pictor-croix {
            background-image: url('../img/pictos.png');
            background-repeat: no-repeat;
        }
        
        .sprite-pictos-picto-big-monde {
            background-position: -10px -10px;
            width: 120px;
            height: 118px;
        }
        
        .sprite-pictos-picto-social {
            background-position: -150px -10px;
            width: 40px;
            height: 33px;
        }
        
        .sprite-pictos-picto-environnement {
            background-position: -150px -63px;
            width: 38px;
            height: 33px;
        }
        
        .sprite-pictos-picto-sport {
            background-position: -10px -148px;
            width: 37px;
            height: 33px;
        }
        
        .sprite-pictos-picto-dev {
            background-position: -67px -148px;
            width: 36px;
            height: 33px;
        }
        
        .sprite-pictos-picto-sante {
            background-position: -123px -148px;
            width: 26px;
            height: 33px;
        }
        
        .sprite-pictos-picto-loisir {
            background-position: -210px -10px;
            width: 33px;
            height: 33px;
        }
        
        .sprite-pictos-picto-artisanat {
            background-position: -210px -63px;
            width: 31px;
            height: 33px;
        }
        
        .sprite-pictos-picto-alimentaire {
            background-position: -210px -116px;
            width: 20px;
            height: 33px;
        }
        
        .sprite-pictos-picto-question {
            background-position: -10px -201px;
            width: 29px;
            height: 25px;
        }
        
        .sprite-pictos-picto-heart {
            background-position: -59px -201px;
            width: 29px;
            height: 25px;
        }
        
        .sprite-pictos-picto-monde {
            background-position: -263px -10px;
            width: 28px;
            height: 27px;
        }
        
        .sprite-pictos-picto-loupe {
            background-position: -263px -57px;
            width: 27px;
            height: 27px;
        }
        
        .sprite-pictos-picto-user {
            background-position: -263px -104px;
            width: 18px;
            height: 25px;
        }
        
        .sprite-pictos-picto-rocket {
            background-position: -263px -149px;
            width: 25px;
            height: 25px;
        }
        
        .sprite-pictos-pictor-croix {
            background-position: -263px -194px;
            width: 14px;
            height: 14px;
        }
        
        @media screen and (-webkit-min-device-pixel-ratio: 1.0), screen and (min--moz-device-pixel-ratio: 1.0), screen and (-o-min-device-pixel-ratio: 100/100), screen and (min-device-pixel-ratio: 1.0), screen and (min-resolution: 1.0dppx) {
            .sprite-pictos-picto-big-monde,
    .sprite-pictos-picto-social,
    .sprite-pictos-picto-environnement,
    .sprite-pictos-picto-sport,
    .sprite-pictos-picto-dev,
    .sprite-pictos-picto-sante,
    .sprite-pictos-picto-loisir,
    .sprite-pictos-picto-artisanat,
    .sprite-pictos-picto-alimentaire,
    .sprite-pictos-picto-question,
    .sprite-pictos-picto-heart,
    .sprite-pictos-picto-monde,
    .sprite-pictos-picto-loupe,
    .sprite-pictos-picto-user,
    .sprite-pictos-picto-rocket,
    .sprite-pictos-pictor-croix {
                background-image: url('../img/pictos.png');
                -webkit-background-size: 301px 236px;
                -moz-background-size: 301px 236px;
                background-size: 301px 236px;
            }
        }
        