#page-projets{

    .projetThumb {
        margin-bottom: 30px;
    }
    .projetThumb .item-body {
     //  height: 160px;
       border-left: 1px solid @greyLight;
       border-right: 1px solid @greyLight;
    }

    .projetThumb .item-metabottom {
        border-left: 1px solid @greyDark;
        border-right: 1px solid @greyDark;
    }

}
