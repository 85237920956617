#page-aiderLeMonde{
    .catIcon{
	width: 95px;
	height: 95px;
	margin: 10px;
	display: inline-block;
    }
    .catIcon-alimentaire{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_alimentaire.png");
    }
    .catIcon-alimentaire.act, .catIcon-alimentaire:hover{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_alimentaire_act.png");
    }
    .catIcon-environnement{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_environnement.png");
    }
    .catIcon-environnement.act, .catIcon-environnement:hover{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_environnement_act.png");
    }
    .catIcon-social{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_social.png");
    }
    .catIcon-social.act, .catIcon-social:hover{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_social_act.png");
    }
    .catIcon-artisanat{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_artisanat.png");
    }
    .catIcon-artisanat.act, .catIcon-artisanat:hover{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_artisanat_act.png");
    }
    .catIcon-sport{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_sport.png");
    }
    .catIcon-sport.act, .catIcon-sport:hover{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_sport_act.png");
    }
    .catIcon-art{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_art.png");
    }
    .catIcon-art.act, .catIcon-art:hover{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_art_act.png");
    }
    .catIcon-loisirs{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_loisirs.png");
    }
    .catIcon-loisirs.act, .catIcon-loisirs:hover{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_loisirs_act.png");
    }
    .catIcon-developpement{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_developpement.png");
    }
    .catIcon-developpement.act, .catIcon-developpement:hover{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_developpement_act.png");
    }
    .catIcon-sante{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_sante.png");
    }
    .catIcon-sante.act, .catIcon-sante:hover{
	background-image: url("../../bundles/jamain/img/icones/bigIcon_sante_act.png");
    }

    .categorieDiv{
	display: none;
    }
    .categorieDiv.act{
	display: block;
    }

    @media (max-width: 768px) {

        /* test font-size:calc(12px + 1.5vw); */
        h1 {
            font-size: calc(18px + 2vw);
        }

        /* test font-size:calc(12px + 1.5vw); */
        h2 {
            font-size: calc(18px + 2vw);
        }
    }
}
